import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import CreateStoreModal from '../../components/Dashboard/CreateStoreModel';
import { API_ENDPOINTS } from '../../API';

const Stores = () => {
  const [stores, setStores] = useState([]);
  const [jobs, setJobs] = useState([]); // List of jobs with status "started"
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    fetchStores();
    fetchJobs(); // Fetch available jobs with status "started"
  }, []);

  const fetchStores = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      
      const response = await axios.get(API_ENDPOINTS.STORES, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setStores(response.data);
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const fetchJobs = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await axios.get(`${API_ENDPOINTS.JOBS}?status=started`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleRemoveStore = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.delete(`${API_ENDPOINTS.STORES}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setStores(stores.filter(store => store._id !== id));
    } catch (error) {
      console.error('Error removing store:', error);
    }
  };

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-2xl font-bold">Store Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
        >
          <FaPlus className="mr-2" /> Create Store
        </button>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Input Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Output Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">WH</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">CC</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">TBC</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">P</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">R</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">CCG</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">TBCG</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">W</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Released By</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Accepted By</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Stock ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {stores.map(store => (
              <tr key={store._id}>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(store.inputDate).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(store.outputDate).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.wh}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.cc}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.tbc}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.p}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.r}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.ccg}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.tbcg}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.w}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.releasedBy}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.acceptedBy}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{store.stockId}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleRemoveStore(store._id)}
                      className="flex items-center px-3 py-1 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                    >
                      <FaTrashAlt className="mr-2" /> Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {stores.length === 0 && (
              <tr>
                <td colSpan="14" className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                  No stores available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <CreateStoreModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        onSave={fetchStores} // Refresh store list after adding
        jobs={jobs} // Pass jobs to the create modal
      />
    </div>
  );
};

export default Stores;
