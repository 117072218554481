// components/Dashboard/Supplier/AddSupplierModal.js
import React from "react";
import Modal from "react-modal";

const AddSupplierModal = ({ isOpen, onRequestClose, newSupplier, handleInputChange, handleAddSupplier }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Add Supplier"
    className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold">Add New Supplier</h2>
      <form className="space-y-4">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Trader</label>
          <input
            type="text"
            name="company"
            value={newSupplier.company}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Verification Type</label>
          <select
            name="verification_type"
            value={newSupplier.verification_type}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          >
            <option value="">Select Verification Type</option>
            <option value="Organic">Organic</option>
            <option value="Non-Organic">Non-Organic</option>
            <option value="Fair Trade">Fair Trade</option>
            <option value="Rainforest">Rainforest</option>
          </select>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Responsible Person</label>
          <input
            type="text"
            name="responsible_person"
            value={newSupplier.responsible_person}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Contact Number</label>
          <input
            type="text"
            name="contact_number"
            value={newSupplier.contact_number}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Trader Address</label>
          <input
            type="text"
            name="companyAddress"
            value={newSupplier.companyAddress}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Trader Code</label>
          <input
            type="text"
            name="supplier_code"
            value={newSupplier.supplier_code}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleAddSupplier}
            className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
          >
            Add Supplier
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="px-4 py-2 ml-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </Modal>
);

export default AddSupplierModal;
