import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle, FaCog, FaBell, FaSignOutAlt, FaQuestionCircle } from 'react-icons/fa'; // Importing icons
import { useAuth } from "../../context/AuthContext";

const Header = ({ onMenuItemClick }) => {
  const { logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigation = (component, path) => {
    console.log(`Navigating to ${path} and setting component to ${component}`); // Debugging line
    onMenuItemClick(component);
    navigate(path);
    setIsDropdownOpen(false); // Close dropdown after navigation
  };

  return (
    <header className="py-4 bg-green-500 rounded-lg shadow-lg">
      <div className="flex justify-between px-4 mx-auto sm:px-6 lg:px-8">
        {/* Logo Text */}
        <div>
          <h1 className="text-2xl font-semibold text-white">Dashboard</h1>
        </div>

        {/* User and Support Icons */}
        <div className="relative">
          {/* User Icon */}
          <FaUserCircle className="text-4xl text-white transition duration-300 ease-in-out transform cursor-pointer hover:scale-110" onClick={toggleDropdown} />

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute right-0 w-48 bg-green-600 rounded-lg shadow-lg top-10">
              <ul className="p-2">
                <li onClick={() => handleNavigation("MyProfile", "/dashboard/profile")} className="flex items-center px-4 py-2 text-white rounded-lg cursor-pointer hover:bg-green-700">
                  <FaUserCircle className="mr-2" />
                  My Profile
                </li>
                <li onClick={() => handleNavigation("Notifications", "/dashboard/notifications")} className="flex items-center px-4 py-2 text-white rounded-lg cursor-pointer hover:bg-green-700">
                  <FaBell className="mr-2" />
                  Notifications
                </li>
                <li onClick={() => handleNavigation("Settings", "/dashboard/settings")} className="flex items-center px-4 py-2 text-white rounded-lg cursor-pointer hover:bg-green-700">
                  <FaCog className="mr-2" />
                  Settings
                </li>
                <li onClick={() => handleNavigation("Help", "/dashboard/help")} className="flex items-center px-4 py-2 text-white rounded-lg cursor-pointer hover:bg-green-700">
                  <FaQuestionCircle className="mr-2" />
                  Help
                </li>
                <li onClick={logout} className="flex items-center px-4 py-2 text-white rounded-lg cursor-pointer hover:bg-red-700">
                  <FaSignOutAlt className="mr-2" />
                  Log Out
                </li>
                {/* Add more dropdown items here */}
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
