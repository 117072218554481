const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
  LOGIN: `${BASE_URL}/api/auth/login`,
  REGISTER: `${BASE_URL}/api/auth/register`,
  FARMERS: `${BASE_URL}/api/farmers`,
  ADMIN_PASSWORD_RECOVER: `${BASE_URL}/api/auth/admin-password-recover`,
  CHANGE_PASSWORD: `${BASE_URL}/api/auth/profile/change-password`,
  PRODUCTS: `${BASE_URL}/api/products`,
  JOBS: `${BASE_URL}/api/jobs`,
  LABELS: `${BASE_URL}/api/labels`,
  USERS: `${BASE_URL}/api/users`,
  STORES: `${BASE_URL}/api/stores`,
  PROFILE: `${BASE_URL}/api/auth/profile`,
  UPDATE_PROFILE_PIC: `${BASE_URL}/api/auth/profile/upload-profile_image`,
  SUPPLIERS: `${BASE_URL}/api/suppliers`,
  ORDERS: `${BASE_URL}/api/orders`,
  RECEIVINGS: `${BASE_URL}/api/receivings`,  // Add this line
  BILLINGS: `${BASE_URL}/api/billings`,
  BUYERS: `${BASE_URL}/api/buyers`,  // Add this line
};
