import React from "react";

const FooterBar = () => {
    return (
        <footer className="p-4 mx-4 mb-4 text-center bg-white rounded-lg shadow-lg">
        <p className="text-sm text-gray-600">© 2024 Maarji Herbal. All rights reserved, Developed by Zylon Labs</p>
        </footer>
    );
    }
    
export default FooterBar;