import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import AddReceivingModal from "../../components/Dashboard/Receiving/AddReceivingModel";
import { API_ENDPOINTS } from "../../API";

const Receiving = () => {
  const [orders, setOrders] = useState([]);
  const [receivings, setReceivings] = useState([]); // State to hold receiving data
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);

  useEffect(() => {
    fetchApprovedOrders();
    fetchReceivings(); // Fetch the receiving data
  }, []);

  const fetchApprovedOrders = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.ORDERS}?status=Approved`);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching approved orders:", error);
    }
  };

  const fetchReceivings = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.RECEIVINGS);
      setReceivings(response.data);
    } catch (error) {
      console.error("Error fetching receivings:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const openModalForOrder = (order) => {
    setCurrentOrder(order);
    setModalIsOpen(true);
  };

  const handleAddReceiving = async ({ orderID, quantity, wastage, return: returnQty, tiir }) => {
    if (!orderID) {
      console.error("No valid order selected");
      return;
    }

    try {
      const receivingData = {
        orderID,
        receivetype: currentOrder.ordertype,
        quantity,
        wastage,
        return: returnQty,
        tiir,
        receivestatus: "Pending"
      };

      await axios.post(API_ENDPOINTS.RECEIVINGS, receivingData);

      // Update the receiving data and orders after adding a new receiving
      fetchApprovedOrders();
      fetchReceivings();
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error adding receiving:", error);
    }
  };

  // Helper function to check if an order has a receiving with a specific orderID
  const hasReceivingForOrder = (orderID) => {
    return receivings.some(receiving => receiving.orderID === orderID);
  };

  const filteredOrders = orders
    .filter((order) => order.orderitem.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((order) => !hasReceivingForOrder(order._id)); // Filter out orders that already have a receiving

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Receiving Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <div className="flex items-center w-full space-x-4">
          <input
            type="text"
            placeholder="Search by order item"
            value={searchTerm}
            onChange={handleSearch}
            className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Supplier ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Item</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Quantity</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Receiving Point</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">TIIR Status</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOrders.map((order) => (
              <tr key={order._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{order.supplierID}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.orderitem}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(order.orderdate).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.quantity}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.receiving_point}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {order.tiirstatus || "NOT OK"}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => openModalForOrder(order)}
                      className="flex items-center px-3 py-1 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600"
                    >
                      <FaPlus className="mr-2" /> Add Receiving
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {currentOrder && (
        <AddReceivingModal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          orderQuantity={currentOrder.quantity}
          handleAddReceiving={handleAddReceiving}
          currentOrder={currentOrder}
        />
      )}
    </div>
  );
};

export default Receiving;
