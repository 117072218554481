// components/Dashboard/Buyer/SuccessModal.js
import React from "react";
import Modal from "react-modal";

const SuccessModal = ({ isOpen, onRequestClose }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Success"
    className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold text-center">Success</h2>
      <div className="flex justify-center">
        <button
          onClick={onRequestClose}
          className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
        >
          Close
        </button>
      </div>
    </div>
  </Modal>
);

export default SuccessModal;
