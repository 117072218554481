import React, { useState } from 'react';
import axios from 'axios';
import { FaSave, FaTimes } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../API';

const ChangePasswordPopup = ({ user, onClose, onSave }) => {
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handlePasswordChange = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      setLoading(true);
      await axios.post(API_ENDPOINTS.ADMIN_PASSWORD_RECOVER,
        { userId: user._id, newPassword },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSuccess('Password changed successfully');
      setLoading(false);
      onSave();
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="relative w-full max-w-md p-6 mx-auto bg-white rounded-lg shadow-lg">
        <button onClick={onClose} className="absolute text-gray-600 top-2 right-2 hover:text-gray-900">
          <FaTimes size={24} />
        </button>
        <h2 className="mb-4 text-xl font-semibold">Change Password</h2>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="mb-4">
          <label className="block mb-1 font-semibold text-gray-700">New Password</label>
          <input
            type="password"
            className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
          />
        </div>
        <div className="flex justify-end mt-6">
          <button onClick={handlePasswordChange} className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600">
            <FaSave className="mr-2" /> Update Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPopup;
