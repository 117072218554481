import React, { useEffect } from "react";
import Modal from "react-modal";

const EditBuyerModal = ({ isOpen, onRequestClose, currentBuyer, handleEditInputChange, handleUpdateBuyer }) => {
  useEffect(() => {
    if (isOpen) {
      document.getElementById('root').classList.add('filter', 'blur-sm', 'pointer-events-none');
    } else {
      document.getElementById('root').classList.remove('filter', 'blur-sm', 'pointer-events-none');
    }
    return () => document.getElementById('root').classList.remove('filter', 'blur-sm', 'pointer-events-none');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Buyer"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      ariaHideApp={false}
    >
      <div className="w-full max-w-md p-4 bg-white rounded-lg shadow-lg sm:max-w-lg md:max-w-xl">
        <h2 className="mb-3 text-xl font-semibold text-center text-gray-800">Edit Buyer</h2>
        {currentBuyer && (
          <form className="space-y-2">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label className="block mb-1 text-xs font-medium text-gray-600">Company</label>
                <input
                  type="text"
                  name="company"
                  value={currentBuyer.company}
                  onChange={handleEditInputChange}
                  className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <div>
                <label className="block mb-1 text-xs font-medium text-gray-600">Contact Person</label>
                <input
                  type="text"
                  name="contact_person"
                  value={currentBuyer.contact_person}
                  onChange={handleEditInputChange}
                  className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <div>
                <label className="block mb-1 text-xs font-medium text-gray-600">Contact Number</label>
                <input
                  type="text"
                  name="contact_number"
                  value={currentBuyer.contact_number}
                  onChange={handleEditInputChange}
                  className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <div>
                <label className="block mb-1 text-xs font-medium text-gray-600">Email</label>
                <input
                  type="email"
                  name="email"
                  value={currentBuyer.email}
                  onChange={handleEditInputChange}
                  className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <div>
                <label className="block mb-1 text-xs font-medium text-gray-600">Company Address</label>
                <input
                  type="text"
                  name="companyAddress"
                  value={currentBuyer.companyAddress}
                  onChange={handleEditInputChange}
                  className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
              <div>
                <label className="block mb-1 text-xs font-medium text-gray-600">Buyer Code</label>
                <input
                  type="text"
                  name="buyer_code"
                  value={currentBuyer.buyer_code}
                  onChange={handleEditInputChange}
                  className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={handleUpdateBuyer}
                className="px-3 py-2 text-xs font-medium text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
              >
                Update Buyer
              </button>
              <button
                type="button"
                onClick={onRequestClose}
                className="px-3 py-2 text-xs font-medium text-white bg-gray-500 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default EditBuyerModal;
