import React from 'react';
import Modal from 'react-modal';
import IDCard from './IDCard';

const IDCardModal = ({ isOpen, onRequestClose, user }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User ID Card"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      ariaHideApp={false}
    >
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-lg print:shadow-none print:bg-transparent print:border-none">
        {/* Page Break to push content to the second page */}
        <div className="page-break"></div>

        {/* This container holds the ID card on the second page */}
        <div className="print-page-2">
          <IDCard user={user} />
        </div>

        {/* Buttons for actions, hidden during print */}
        <div className="flex justify-end mt-6 space-x-4 print-hidden">
          <button
            onClick={handlePrint}
            className="px-5 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
          >
            Print as PDF
          </button>
          <button
            onClick={onRequestClose}
            className="px-5 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IDCardModal;
