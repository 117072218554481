import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaSave, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { API_ENDPOINTS } from '../../API';

const CreateLabelModal = ({ isOpen, onRequestClose, onSave }) => {
  const [newLabel, setNewLabel] = useState({
    type: '',
    productId: '',
    productName: '',
    ingredients: '',
    quantity: '',
    grade: '',
    manufacturingDate: '',
    expiryDate: '',
    batchNumber: '',
    purchaseOrderNumber: '',
    lotNumber: '',
    referenceNumber: ''
  });
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchProducts();
    }
  }, [isOpen]);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found');
        return;
      }
      const response = await axios.get(API_ENDPOINTS.PRODUCTS, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(response.data);
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
    }
  };

  const handleProductChange = (e) => {
    const selectedProductId = e.target.value;
    const selectedProduct = products.find(product => product._id === selectedProductId);
    setNewLabel({
      ...newLabel,
      productId: selectedProductId,
      productName: selectedProduct ? selectedProduct.name : '' // Assuming 'name' is the correct field
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLabel({ ...newLabel, [name]: value });
  };

  const handleCreateLabel = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }
  
    console.log(newLabel); // Add this line to check if the productName and productId are being set correctly
  
    try {
      setLoading(true);
      await axios.post(API_ENDPOINTS.LABELS, newLabel, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setSuccess('Label created successfully');
      setLoading(false);
      onSave(); // Refresh the label list
      onRequestClose(); // Close the modal
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Create Label Modal"
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
    >
      <div className="relative w-full max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
        <button
          onClick={onRequestClose}
          className="absolute text-gray-600 top-2 right-2 hover:text-gray-900"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="mb-4 text-xl font-semibold">Create New Label</h2>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <form>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Type</label>
            <input
              type="text"
              name="type"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newLabel.type}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Product Name</label>
            <select
              name="productId"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newLabel.productId}
              onChange={handleProductChange}
            >
              <option value="" disabled>Select a product</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name} ({product.organic ? 'Organic' : 'Conventional'})  {/* Assuming 'name' is the correct field */}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Selected Product</label>
            <input
              type="text"
              name="productName"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newLabel.productName}
              onChange={handleInputChange}
              readOnly
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Ingredients</label>
            <input
              type="text"
              name="ingredients"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newLabel.ingredients}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Quantity</label>
            <input
              type="text"
              name="quantity"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newLabel.quantity}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Grade</label>
            <input
              type="text"
              name="grade"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newLabel.grade}
              onChange={handleInputChange}
            />
          </div>
          {/* Additional fields here */}
          <div className="flex justify-end mt-6">
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 mr-2 bg-gray-300 rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleCreateLabel}
              className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600"
            >
              <FaSave className="mr-2" /> Create Label
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateLabelModal;
