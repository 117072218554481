import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrashAlt, FaLock, FaIdCard } from 'react-icons/fa';
import EditUserPopup from '../../components/Dashboard/EditUserPopup';
import ChangePasswordPopup from '../../components/Dashboard/ChangePasswordPopup';
import AddUserPopup from '../../components/Dashboard/AddUserPopup';
import IDCardModal from '../../components/Dashboard/User/IDCardModal'; // Import the IDCardModal
import { API_ENDPOINTS } from '../../API';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(20);
  const [editingUser, setEditingUser] = useState(null);
  const [changingPasswordUser, setChangingPasswordUser] = useState(null);
  const [addingUser, setAddingUser] = useState(false);
  const [selectedUserForID, setSelectedUserForID] = useState(null); // State for selected user for ID card

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }

        const response = await axios.get(API_ENDPOINTS.USERS, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleEditUser = (user) => {
    if (user && user._id) {
      setEditingUser(user);
    } else {
      console.error('Invalid user data:', user);
    }
  };

  const handleChangePassword = (user) => {
    setChangingPasswordUser(user);
  };

  const handleAddUser = () => {
    setAddingUser(true);
  };

  const handleClosePopup = () => {
    setEditingUser(null);
    setChangingPasswordUser(null);
    setAddingUser(false);
    setSelectedUserForID(null); // Reset the selected user for ID card
  };

  const handleSaveUser = (updatedUser) => {
    setUsers(users.map(user => (user._id === updatedUser._id ? updatedUser : user)));
    setEditingUser(null);
  };

  const handleSavePassword = () => {
    setChangingPasswordUser(null);
  };

  const handleSaveNewUser = (newUser) => {
    setUsers([...users, newUser]);
    setAddingUser(false);
  };

  const handleRemoveUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      
      await axios.delete(`${API_ENDPOINTS.USERS}/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUsers(users.filter(user => user._id !== userId));
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const handleShowIDCard = (user) => {
    setSelectedUserForID(user); // Set the selected user to display their ID card
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const filteredUsers = users.filter(user =>
    user.username ? user.username.toLowerCase().includes(searchTerm.toLowerCase()) : false
  );
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-2xl font-bold">User Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <div className="flex items-center w-full space-x-4">
          <input 
            type="text" 
            placeholder="Search by username" 
            value={searchTerm} 
            onChange={handleSearch} 
            className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
          />
          <div className="flex items-center space-x-4">
            <button 
              onClick={handleAddUser} 
              className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              <FaPlus className="mr-2" /> Add User
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Username
              </th>
              <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Email
              </th>
              <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Contact
              </th>
              <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentUsers.map(user => (
              <tr key={user._id}>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{user.username}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{user.email}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{user.contact_number}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button onClick={() => handleShowIDCard(user)} className="flex items-center px-3 py-1 text-white bg-indigo-500 rounded-md shadow-lg hover:bg-indigo-600">
                      <FaIdCard className="mr-2" /> User ID Print
                    </button>
                    <button onClick={() => handleEditUser(user)} className="flex items-center px-3 py-1 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600">
                      <FaEdit className="mr-2" /> Edit User
                    </button>
                    <button onClick={() => handleChangePassword(user)} className="flex items-center px-3 py-1 text-white bg-blue-500 rounded-md shadow-lg hover:bg-blue-600">
                      <FaLock className="mr-2" /> Change Password
                    </button>
                    <button onClick={() => handleRemoveUser(user._id)} className="flex items-center px-3 py-1 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600">
                      <FaTrashAlt className="mr-2" /> Remove User
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {currentUsers.length === 0 && (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                  No users available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {filteredUsers.length > usersPerPage && (
          <div className="flex items-center justify-end mt-4">
            <nav className="block">
              <ul className="flex pl-0 overflow-hidden list-none rounded">
                {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }, (_, index) => (
                  <li key={index}>
                    <button
                      onClick={() => paginate(index + 1)}
                      className={`px-3 py-1 ml-1 font-semibold leading-tight bg-white border border-gray-300 text-blue-700 hover:bg-gray-200 ${currentPage === index + 1 ? 'bg-gray-200' : ''}`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </div>
      {editingUser && editingUser._id && <EditUserPopup user={editingUser} onClose={handleClosePopup} onSave={handleSaveUser} />}
      {changingPasswordUser && <ChangePasswordPopup user={changingPasswordUser} onClose={handleClosePopup} onSave={handleSavePassword} />}
      {addingUser && <AddUserPopup onClose={handleClosePopup} onSave={handleSaveNewUser} />}
      {selectedUserForID && <IDCardModal isOpen={true} onRequestClose={handleClosePopup} user={selectedUserForID} />} {/* Display ID card modal */}
    </div>
  );
};

export default Users;
