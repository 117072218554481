import React, { useState } from 'react';
import axios from 'axios';
import {API_ENDPOINTS} from '../API';

const RegisterForm = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.password_confirmation) {
        alert('Passwords do not match');
        return;
    }

    try {
        await axios.post(API_ENDPOINTS.REGISTER, userData);
        alert('Registration successful');
    } catch (error) {
        if (error.response) {
            alert('Registration failed: ' + error.response.data);
        } else if (error.request) {
            alert('No response from server');
        } else {
            alert('Error', error.message);
        }
    }
  };

  return (
    <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
      <h2 className="mb-6 text-3xl font-bold text-center text-gray-700">Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block py-3 text-sm font-medium text-gray-600">Email</label>
          <input type="email" id="email" onChange={handleChange} className="w-full px-3 py-2 border rounded-md" placeholder="Enter your email"/>
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block py-3 text-sm font-medium text-gray-600">Password</label>
          <input type="password" id="password" onChange={handleChange} className="w-full px-3 py-2 border rounded-md" placeholder="Enter your password"/>
        </div>
        <div className="mb-6">
          <label htmlFor="password_confirmation" className="block py-3 text-sm font-medium text-gray-600">Confirm Password</label>
          <input type="password" id="password_confirmation" onChange={handleChange} className="w-full px-3 py-2 border rounded-md" placeholder="Confirm your password"/>
        </div>
        <button type="submit" className="w-full py-3 mt-4 font-medium text-white uppercase bg-green-500 rounded-md shadow hover:bg-green-600 focus:outline-none">
          Register
        </button>
        <p className="pt-5 text-center text-gray-600 text-md">Already have an account? <a href="/" className="text-blue-500">Login</a></p>
      </form>
    </div>
  );
};

export default RegisterForm;
