import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../API';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [newProduct, setNewProduct] = useState({
    name: '',
    type: ''
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }
        
        const response = await axios.get(API_ENDPOINTS.PRODUCTS, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({
      ...newProduct,
      [name]: value
    });
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      const response = await axios.post(API_ENDPOINTS.PRODUCTS, newProduct, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProducts([...products, response.data]);
      setNewProduct({ name: '', type: '' });
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleRemoveProduct = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.delete(`${API_ENDPOINTS.PRODUCTS}/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProducts(products.filter(product => product._id !== id));
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-2xl font-bold">Product Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <input
          type="text"
          placeholder="Search by product name"
          value={searchTerm}
          onChange={handleSearch}
          className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
        />
      </div>

      <form onSubmit={handleAddProduct} className="p-4 mb-4 bg-white rounded-lg shadow-md">
        <h2 className="mb-4 text-xl font-semibold">Add New Product</h2>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Product Name</label>
          <input
            type="text"
            name="name"
            value={newProduct.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium text-gray-700">Type</label>
          <select
            name="type"
            value={newProduct.type}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select Type</option>
            <option value="Organic">Organic</option>
            <option value="Non-Organic">Non-Organic</option>
            <option value="Fairtrade">Fairtrade</option>
            <option value="Rainforest">Rainforest</option>
          </select>
        </div>
        <button
          type="submit"
          className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
        >
          <FaPlus className="mr-2" /> Add Product
        </button>
      </form>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Product
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Type
              </th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredProducts.map(product => (
              <tr key={product._id}>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{product.name}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{product.type}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <button
                    onClick={() => handleRemoveProduct(product._id)}
                    className="flex items-center px-3 py-1 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                  >
                    <FaTrashAlt className="mr-2" /> Remove
                  </button>
                </td>
              </tr>
            ))}
            {filteredProducts.length === 0 && (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                  No products available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
