import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_ENDPOINTS } from "../../API";
import CreateBillingModal from "../../components/Dashboard/Billing/createBilling";

const Billing = () => {
  const [receivings, setReceivings] = useState([]);
  const [billings, setBillings] = useState([]);
  const [orders, setOrders] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReceiving, setCurrentReceiving] = useState(null);

  useEffect(() => {
    fetchReceivings();
    fetchBillings();
    fetchOrders();
  }, []);

  const fetchReceivings = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.RECEIVINGS);
      setReceivings(response.data);
    } catch (error) {
      console.error("Error fetching receivings:", error);
    }
  };

  const fetchBillings = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.BILLINGS);
      setBillings(response.data.map(billing => ({
        ...billing,
        totalAmount: Number(billing.totalAmount),
        finalAmount: Number(billing.finalAmount),
      })));
    } catch (error) {
      console.error("Error fetching billings:", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.ORDERS);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const openModalForReceiving = (receiving) => {
    setCurrentReceiving(receiving);
    setModalIsOpen(true);
  };

  const handleSaveBilling = async (billingData) => {
    try {
      await axios.post(API_ENDPOINTS.BILLINGS, billingData);
      fetchBillings(); // Refresh the billings list
      setModalIsOpen(false); // Close modal after saving
    } catch (error) {
      console.error("Error creating billing:", error);
    }
  };

  const getProductNamesByReceivingId = (receivingId) => {
    const receiving = receivings.find((r) => r._id === receivingId);
    if (receiving) {
      const order = orders.find((o) => o._id === receiving.orderID);
      if (order && Array.isArray(order.orderitem)) {
        return order.orderitem.map((item) => item.productName).join(", ");
      }
    }
    return "Unknown Product";
  };

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Billing Management</h1>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold">Receivings</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Receiving ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Product Names</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Quantity</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {receivings.map((receiving) => (
              <tr key={receiving._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{receiving._id}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{getProductNamesByReceivingId(receiving._id)}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{receiving.quantity}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <button
                    onClick={() => openModalForReceiving(receiving)}
                    className="px-3 py-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                  >
                    Create Billing
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="p-6 mt-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold">Billings</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Billing ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Receiving ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Product Names</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Total Amount</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Final Amount</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {billings.map((billing) => (
              <tr key={billing._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{billing._id}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{billing.receivingID}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{getProductNamesByReceivingId(billing.receivingID)}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{billing.totalAmount}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{billing.finalAmount}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {/* Actions for billing, if any */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modalIsOpen && (
        <CreateBillingModal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          onSave={handleSaveBilling}
          receiving={currentReceiving}
        />
      )}
    </div>
  );
};

export default Billing;
