import React from "react";
import Modal from "react-modal";

// Set the app element to prevent screen readers from reading background content when the modal is open.
Modal.setAppElement('#root');

const EditFarmerModal = ({ isOpen, onRequestClose, currentFarmer, handleEditInputChange, handleUpdateFarmer }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Edit Farmer"
    className="fixed inset-0 flex items-center justify-center p-2 bg-gray-800 bg-opacity-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="w-full max-w-2xl p-4 bg-white rounded-lg shadow-lg">
      <h2 className="mb-3 text-xl font-semibold">Edit Farmer</h2>
      {currentFarmer ? (
        <form className="space-y-3" onSubmit={handleUpdateFarmer}>
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                value={currentFarmer.firstName}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Second Name</label>
              <input
                type="text"
                name="secondName"
                value={currentFarmer.secondName}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Middle Name</label>
              <input
                type="text"
                name="middleName"
                value={currentFarmer.middleName}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Farmer ID</label>
              <input
                type="text"
                name="farmerID"
                value={currentFarmer.farmerID}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">NIC</label>
              <input
                type="text"
                name="NIC"
                value={currentFarmer.NIC}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Contact Number</label>
              <input
                type="text"
                name="contactNumber"
                value={currentFarmer.contactNumber}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Volume (Kg)</label>
              <input
                type="text"
                name="volume"
                value={currentFarmer.volume}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Farm Address</label>
              <input
                type="text"
                name="farmAddress"
                value={currentFarmer.farmAddress}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Home Address</label>
              <input
                type="text"
                name="homeAddress"
                value={currentFarmer.homeAddress}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Longitude</label>
              <input
                type="text"
                step="any"
                name="longitude"
                value={currentFarmer.longitude}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Latitude</label>
              <input
                type="text"
                step="any"
                name="latitude"
                value={currentFarmer.latitude}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Yield Status</label>
              <input
                type="text"
                name="yieldStatus"
                value={currentFarmer.yieldStatus}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Verification Type</label>
              <input
                type="text"
                name="verification_type"
                value={currentFarmer.verification_type}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">Maarji ID</label>
              <input
                type="text"
                name="maargi_id"
                value={currentFarmer.maargi_id}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block mb-1 text-xs font-medium text-gray-700">City</label>
              <input
                type="text"
                name="city"
                value={currentFarmer.city}
                onChange={handleEditInputChange}
                className="w-full px-3 py-1 border border-gray-300 rounded-md"
                required
              />
            </div>
          </div>
          <div className="flex justify-end mt-3">
            <button
              type="submit"
              className="px-3 py-1 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              Update Farmer
            </button>
            <button
              type="button"
              onClick={onRequestClose}
              className="px-3 py-1 ml-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  </Modal>
);

export default EditFarmerModal;
