import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTrashAlt, FaEdit } from "react-icons/fa";
import AddBuyerModal from "../../components/Dashboard/Buyer/AddBuyerModel";
import EditBuyerModal from "../../components/Dashboard/Buyer/EditBuyerModel";
import SuccessModal from "../../components/Dashboard/Buyer/SuccessModel";
import { API_ENDPOINTS } from "../../API";

const Buyers = () => {
  const [buyers, setBuyers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [newBuyer, setNewBuyer] = useState({
    company: "",
    contact_person: "",
    contact_number: "",
    email: "",
    companyAddress: "",
    buyer_code: ""
  });
  const [currentBuyer, setCurrentBuyer] = useState(null);

  useEffect(() => {
    fetchBuyers();
  }, []);

  const fetchBuyers = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.BUYERS);
      setBuyers(response.data);
    } catch (error) {
      console.error("Error fetching buyers:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBuyer({ ...newBuyer, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentBuyer({ ...currentBuyer, [name]: value });
  };

  const generateBuyerID = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const handleAddBuyer = async () => {
    const buyerData = {
      ...newBuyer,
      buyer_id: generateBuyerID(),
    };

    try {
      const response = await axios.post(API_ENDPOINTS.BUYERS, buyerData);
      setBuyers([...buyers, response.data]);
      setModalIsOpen(false);
      setNewBuyer({ company: "", contact_person: "", contact_number: "", email: "", companyAddress: "", buyer_code: "" });
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error adding buyer:", error);
    }
  };

  const handleUpdateBuyer = async () => {
    try {
      const response = await axios.put(`${API_ENDPOINTS.BUYERS}/${currentBuyer._id}`, currentBuyer);
      setBuyers(buyers.map(buyer => buyer._id === currentBuyer._id ? response.data : buyer));
      setEditModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error updating buyer:", error);
    }
  };

  const handleStatusChange = async (id) => {
    try {
      const buyer = buyers.find(buyer => buyer._id === id);
      buyer.status = buyer.status === 'active' ? 'inactive' : 'active';
      const response = await axios.put(`${API_ENDPOINTS.BUYERS}/${id}`, buyer);
      setBuyers(buyers.map(buyer => buyer._id === id ? response.data : buyer));
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleRemoveBuyer = async (id) => {
    try {
      await axios.delete(`${API_ENDPOINTS.BUYERS}/${id}`);
      setBuyers(buyers.filter(buyer => buyer._id !== id));
    } catch (error) {
      console.error("Error removing buyer:", error);
    }
  };

  const openEditModal = (buyer) => {
    setCurrentBuyer(buyer);
    setEditModalIsOpen(true);
  };

  const filteredBuyers = buyers.filter((buyer) =>
    `${buyer.company} ${buyer.contact_person}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Buyers Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <div className="flex items-center w-full space-x-4">
          <input
            type="text"
            placeholder="Search by company or person"
            value={searchTerm}
            onChange={handleSearch}
            className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
          />
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setModalIsOpen(true)}
              className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              <FaPlus className="mr-2" /> Add Buyer
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        {/* Dynamic table content */}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Buyer ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Company
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Contact Person
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Contact Number
              </th>              
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Latest Purchase
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredBuyers.map((buyer) => (
              <tr key={buyer._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {buyer.buyer_code}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {buyer.company}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {buyer.contact_person}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {buyer.contact_number}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <button
                    onClick={() => handleStatusChange(buyer._id)}
                    className={`px-4 py-2 rounded-md ${
                      buyer.status === 'active' ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                    } text-white`}
                  >
                    {buyer.status === 'active' ? 'Deactivate' : 'Activate'}
                  </button>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {buyer.latestPurchase}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => openEditModal(buyer)}
                      className="flex items-center px-3 py-1 text-white bg-blue-500 rounded-md shadow-lg hover:bg-blue-600"
                    >
                      <FaEdit className="mr-2" /> Edit
                    </button>
                    <button
                      onClick={() => handleRemoveBuyer(buyer._id)}
                      className="flex items-center px-3 py-1 ml-2 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                    >
                      <FaTrashAlt className="mr-2" /> Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {/* Display message if no data */}
            {filteredBuyers.length === 0 && (
              <tr>
                <td
                  colSpan="9"
                  className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap"
                >
                  No buyers available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <AddBuyerModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        newBuyer={newBuyer}
        handleInputChange={handleInputChange}
        handleAddBuyer={handleAddBuyer}
      />

      <EditBuyerModal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        currentBuyer={currentBuyer}
        handleEditInputChange={handleEditInputChange}
        handleUpdateBuyer={handleUpdateBuyer}
      />

      <SuccessModal
        isOpen={successModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
      />
    </div>
  );
};

export default Buyers;
