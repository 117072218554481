import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaSave, FaTimes } from "react-icons/fa";
import axios from "axios";
import { API_ENDPOINTS } from "../../API";

const CreateStoreModal = ({ isOpen, onRequestClose, onSave, jobs = [] }) => {
  const [selectedJob, setSelectedJob] = useState("");
  const [newStore, setNewStore] = useState({
    inputDate: "",
    outputDate: "",
    wh: "",
    cc: "",
    tbc: "",
    p: "",
    r: "",
    ccg: "",
    tbcg: "",
    w: "",
    releasedBy: "",
    acceptedBy: "",
    stockId: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("No token found");
          return;
        }
        const response = await axios.get(API_ENDPOINTS.USERS, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.filter((user) => user.role === "user"));
      } catch (error) {
        setError(error.response ? error.response.data : error.message);
      }
    };

    if (isOpen) {
      fetchUsers();
      // Reset the state when modal opens
      setSelectedJob("");
      setNewStore({
        inputDate: "",
        outputDate: "",
        wh: "",
        cc: "",
        tbc: "",
        p: "",
        r: "",
        ccg: "",
        tbcg: "",
        w: "",
        releasedBy: "",
        acceptedBy: "",
        stockId: "",
      });
      setError("");
      setSuccess("");
    }
  }, [isOpen]);

  const handleJobSelection = (e) => {
    const jobId = e.target.value;
    setSelectedJob(jobId);
    setNewStore({ ...newStore, stockId: jobId });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStore({ ...newStore, [name]: value });
  };

  const handleCreateStore = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    try {
      setLoading(true);
      await axios.post(API_ENDPOINTS.STORES, newStore, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSuccess("Store created successfully");
      setLoading(false);
      onSave(); // Refresh the store list
      onRequestClose(); // Close the modal
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Create Store Modal"
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
    >
      <div className="relative w-full max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
        <button
          onClick={onRequestClose}
          className="absolute text-gray-600 top-2 right-2 hover:text-gray-900"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="mb-4 text-xl font-semibold">Create New Store</h2>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}

        <div className="mb-4">
          <label className="block mb-1 font-semibold text-gray-700">
            Select Job
          </label>
          <select
            name="jobId"
            className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
            value={selectedJob}
            onChange={handleJobSelection}
          >
            <option value="" disabled>
              Select a job
            </option>
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <option key={job._id} value={job.jobId}>
                  {job.jobId} ({job.companyName})
                </option>
              ))
            ) : (
              <option value="" disabled>
                No jobs available
              </option>
            )}
          </select>
        </div>

        {selectedJob && (
          <div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h2 className="mb-4 text-xl font-semibold">Store Details</h2>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    Input Date
                  </label>
                  <input
                    type="date"
                    name="inputDate"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.inputDate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    Output Date
                  </label>
                  <input
                    type="date"
                    name="outputDate"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.outputDate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    WH
                  </label>
                  <input
                    type="text"
                    name="wh"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.wh}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    CC
                  </label>
                  <input
                    type="text"
                    name="cc"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.cc}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    TBC
                  </label>
                  <input
                    type="text"
                    name="tbc"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.tbc}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h2 className="mb-4 text-xl font-semibold">Other Details</h2>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    P
                  </label>
                  <input
                    type="text"
                    name="p"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.p}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    R
                  </label>
                  <input
                    type="text"
                    name="r"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.r}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    CCG
                  </label>
                  <input
                    type="text"
                    name="ccg"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.ccg}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    TBCG
                  </label>
                  <input
                    type="text"
                    name="tbcg"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.tbcg}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    W
                  </label>
                  <input
                    type="text"
                    name="w"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.w}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    Released By
                  </label>
                  <select
                    name="releasedBy"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.releasedBy}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select a user
                    </option>
                    {users.map((user) => (
                      <option key={user._id} value={user.username}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-semibold text-gray-700">
                    Accepted By
                  </label>
                  <select
                    name="acceptedBy"
                    className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                    value={newStore.acceptedBy}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select a user
                    </option>
                    {users.map((user) => (
                      <option key={user._id} value={user.username}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <button
                onClick={onRequestClose}
                className="px-4 py-2 mr-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateStore}
                className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600"
              >
                <FaSave className="mr-2" /> Create Store
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateStoreModal;
