import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { API_ENDPOINTS } from "../../../API";

const AddOrderModal = ({ isOpen, onRequestClose, newOrder, handleInputChange, handleAddOrder }) => {
  const [selectionType, setSelectionType] = useState("Company");
  const [suppliers, setSuppliers] = useState([]);
  const [farmers, setFarmers] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (isOpen) {
      document.getElementById('root').classList.add('filter', 'blur-sm', 'pointer-events-none');
      fetchSuppliers();
      fetchFarmers();
      fetchProducts();
    } else {
      document.getElementById('root').classList.remove('filter', 'blur-sm', 'pointer-events-none');
    }

    return () => document.getElementById('root').classList.remove('filter', 'blur-sm', 'pointer-events-none');
  }, [isOpen]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.SUPPLIERS);
      setSuppliers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  const fetchFarmers = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.FARMERS}`);
      setFarmers(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching farmers:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.PRODUCTS);
      setProducts(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSelectionTypeChange = (e) => {
    setSelectionType(e.target.value);
    handleInputChange({ target: { name: "supplierID", value: "" } }); // Reset supplierID value when selection changes
  };

  const handleAddOrderClick = async () => {
    try {
      const response = await axios.post(API_ENDPOINTS.ORDERS, {
        supplierID: newOrder.supplierID,
        orderitem: newOrder.orderitem,
        orderdate: new Date().toISOString(), // Set current date and time
        quantity: newOrder.quantity.toString(),  // Convert quantity to string
        receive_date: newOrder.receive_date,
        receiving_point: newOrder.receiving_point,
        orderstatus: 'Pending'  // Default value
      });
      console.log('Order added successfully:', response.data);
      onRequestClose(); // Close the modal
    } catch (error) {
      console.error("Error adding order:", error.response?.data || error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Order"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      ariaHideApp={false}
    >
      <div className="w-full max-w-sm p-4 bg-white rounded-lg shadow-lg sm:max-w-md md:max-w-lg">
        <h2 className="mb-3 text-xl font-semibold text-center text-gray-800">Add New Order</h2>
        <form className="space-y-3">
          <div>
            <label className="block mb-1 text-xs font-medium text-gray-600">Type</label>
            <select
              value={selectionType}
              onChange={handleSelectionTypeChange}
              className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="Company">Company</option>
              <option value="Individual">Individual</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 text-xs font-medium text-gray-600">
              {selectionType === "Company" ? "Supplier ID" : "Farmer ID"}
            </label>
            <select
              name="supplierID"
              value={newOrder.supplierID || ""} // Ensure supplierID is defined
              onChange={handleInputChange}
              className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="">Select {selectionType === "Company" ? "Supplier" : "Farmer"}</option>
              {selectionType === "Company" && suppliers.length > 0 && suppliers.map((supplier) => (
                <option key={supplier.supplier_id} value={supplier.supplier_id}>
                  {supplier.company} ({supplier.supplier_id})
                </option>
              ))}
              {selectionType === "Individual" && farmers.length > 0 && farmers.map((farmer) => (
                <option key={farmer._id} value={farmer._id}>
                  {`${farmer.firstName} ${farmer.secondName}`} ({farmer.maargi_id})
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-1 text-xs font-medium text-gray-600">Order Item</label>
            <select
              name="orderitem"
              value={newOrder.orderitem || ""} // Ensure orderitem is defined
              onChange={handleInputChange}
              className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="">Select Product</option>
              {products.length > 0 && products.map((product) => (
                <option key={product._id} value={product.name}>
                  {product.name} ({product.type})
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block mb-1 text-xs font-medium text-gray-600">Quantity</label>
            <input
              type="text"  // Change to text to handle string values
              name="quantity"
              value={newOrder.quantity || ""} // Ensure quantity is defined
              onChange={handleInputChange}
              className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>
          <div>
            <label className="block mb-1 text-xs font-medium text-gray-600">Receive Date</label>
            <input
              type="date"
              name="receive_date"
              value={newOrder.receive_date || ""} // Ensure receive_date is defined
              onChange={handleInputChange}
              className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>
          {/* Add receiving point as a two dropdown selection, Madawala, Arangala */}
          <div>
            <label className="block mb-1 text-xs font-medium text-gray-600">Receiving Point</label>
            <select
              name="receiving_point"
              value={newOrder.receiving_point || ""} // Ensure receiving_point is defined
              onChange={handleInputChange}
              className="w-full px-2 py-1 text-xs border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="">Select Receiving Point</option>
              <option value="Madawala">Madawala</option>
              <option value="Arangala">Arangala</option>
            </select>
          </div>
          
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={handleAddOrderClick}
              className="px-3 py-2 text-xs font-medium text-white bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              Add Order
            </button>
            <button
              type="button"
              onClick={onRequestClose}
              className="px-3 py-2 text-xs font-medium text-white bg-gray-500 rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddOrderModal;
