import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTrashAlt, FaCheck, FaTimes } from "react-icons/fa";
import AddOrderModal from "../../components/Dashboard/Orders/AddOrderModel";
import EditOrderModal from "../../components/Dashboard/Orders/EditOrderModel";
import SuccessModal from "../../components/Dashboard/Orders/SuccessModel";
import ApprovalModal from "../../components/Dashboard/Orders/ApprovalModel";
import { API_ENDPOINTS } from "../../API";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [receivings, setReceivings] = useState([]); // State to hold receiving data
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [approvalModalIsOpen, setApprovalModalIsOpen] = useState(false);
  const [newOrder, setNewOrder] = useState({
    supplierID: "",
    orderitem: "",
    orderdate: "",
    quantity: "",
    receive_date: "",
    receiving_point: "",
    ordertype: "",
    orderstatus: "Pending"
  });
  const [currentOrder, setCurrentOrder] = useState(null);
  const [orderToApprove, setOrderToApprove] = useState(null);

  useEffect(() => {
    fetchOrders();
    fetchReceivings(); // Fetch receiving data to compare with orders
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.ORDERS);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchReceivings = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.RECEIVINGS);
      setReceivings(response.data);
    } catch (error) {
      console.error("Error fetching receivings:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOrder({ ...newOrder, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentOrder({ ...currentOrder, [name]: value });
  };

  const handleAddOrder = async () => {
    try {
      await axios.post(API_ENDPOINTS.ORDERS, { ...newOrder, orderstatus: "Pending" });
      fetchOrders(); // Refresh the orders list
      setModalIsOpen(false);
      setNewOrder({
        supplierID: "",
        orderitem: "",
        orderdate: "",
        quantity: "",
        receive_date: "",
        receiving_point: "",
        ordertype: "",
        orderstatus: "Pending"
      });
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error adding order:", error);
    }
  };

  const handleUpdateOrder = async () => {
    try {
      const response = await axios.put(`${API_ENDPOINTS.ORDERS}/${currentOrder._id}`, currentOrder);
      setOrders(orders.map(order => order._id === currentOrder._id ? response.data : order));
      setEditModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleRemoveOrder = async (id) => {
    try {
      await axios.delete(`${API_ENDPOINTS.ORDERS}/${id}`);
      setOrders(orders.filter(order => order._id !== id));
    } catch (error) {
      console.error("Error removing order:", error);
    }
  };

  const handleApproveOrder = async () => {
    try {
      const response = await axios.put(`${API_ENDPOINTS.ORDERS}/${orderToApprove._id}`, {
        ...orderToApprove,
        orderstatus: "Approved"
      });
      setOrders(orders.map(order => order._id === orderToApprove._id ? response.data : order));
      setApprovalModalIsOpen(false);
    } catch (error) {
      console.error("Error approving order:", error);
    }
  };

  const handleUnapproveOrder = async () => {
    try {
      const response = await axios.put(`${API_ENDPOINTS.ORDERS}/${orderToApprove._id}`, {
        ...orderToApprove,
        orderstatus: "Pending"
      });
      setOrders(orders.map(order => order._id === orderToApprove._id ? response.data : order));
      setApprovalModalIsOpen(false);
    } catch (error) {
      console.error("Error unapproving order:", error);
    }
  };

  const openApprovalModal = (order) => {
    setOrderToApprove(order);
    setApprovalModalIsOpen(true);
  };

  // Helper function to check if an order has a corresponding receiving
  const hasReceivingForOrder = (orderID) => {
    return receivings.some(receiving => receiving.orderID === orderID);
  };

  // Filter orders based on the search term and exclude those with corresponding receivings
  const filteredOrders = orders
    .filter((order) => order.orderitem.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((order) => !hasReceivingForOrder(order._id)); // Exclude orders with receivings

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Orders Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <div className="flex items-center w-full space-x-4">
          <input
            type="text"
            placeholder="Search by order item"
            value={searchTerm}
            onChange={handleSearch}
            className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
          />
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setModalIsOpen(true)}
              className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              <FaPlus className="mr-2" /> Add Order
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Supplier ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Item</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Quantity</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Receive Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Receiving Point</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Status</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredOrders.map((order) => (
              <tr key={order._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{order.supplierID}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.orderitem}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(order.orderdate).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.quantity}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(order.receive_date).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.receiving_point}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{order.orderstatus}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => openApprovalModal(order)}
                      className={`flex items-center px-3 py-1 text-white ${order.orderstatus === 'Pending' ? 'bg-blue-500 hover:bg-blue-600' : 'bg-yellow-500 hover:bg-yellow-600'} rounded-md shadow-lg`}
                    >
                      {order.orderstatus === 'Pending' ? <FaCheck className="mr-2" /> : <FaTimes className="mr-2" />}
                      {order.orderstatus === 'Pending' ? 'Approve' : 'Unapprove'}
                    </button>
                    <button
                      onClick={() => handleRemoveOrder(order._id)}
                      className="flex items-center px-3 py-1 ml-2 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                    >
                      <FaTrashAlt className="mr-2" /> Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {filteredOrders.length === 0 && (
              <tr>
                <td colSpan="8" className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                  No orders available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <AddOrderModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        newOrder={newOrder}
        handleInputChange={handleInputChange}
        handleAddOrder={handleAddOrder}
      />

      <EditOrderModal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        currentOrder={currentOrder}
        handleEditInputChange={handleEditInputChange}
        handleUpdateOrder={handleUpdateOrder}
      />

      <SuccessModal
        isOpen={successModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
      />

      <ApprovalModal
        isOpen={approvalModalIsOpen}
        onRequestClose={() => setApprovalModalIsOpen(false)}
        onApprove={handleApproveOrder}
        onUnapprove={handleUnapproveOrder}
        orderStatus={orderToApprove?.orderstatus}
      />
    </div>
  );
};

export default Orders;
