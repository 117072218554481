// components/ExpiryNotificationModal.js
import React, { useEffect } from "react";
import Modal from "react-modal";

const ExpiryNotificationModal = ({ isOpen, onRequestClose }) => {
  useEffect(() => {
    if (isOpen) {
      // Add blur effect to the main content
      document.getElementById('root').classList.add('filter', 'blur-sm', 'pointer-events-none');
    } else {
      // Remove blur effect
      document.getElementById('root').classList.remove('filter', 'blur-sm', 'pointer-events-none');
    }

    // Cleanup on component unmount
    return () => document.getElementById('root').classList.remove('filter', 'blur-sm', 'pointer-events-none');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Session Expired"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      ariaHideApp={false}
    >
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-2xl font-bold text-center">Session Expired</h2>
        <p className="text-center text-gray-700">Your session has expired. Please log in again.</p>
        <div className="flex justify-center mt-6">
          <button
            onClick={onRequestClose}
            className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
          >
            Log in Again
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ExpiryNotificationModal;
