import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTrashAlt, FaEdit } from "react-icons/fa";
import AddSupplierModal from "../../components/Dashboard/Supplier/AddSupplierModel";
import EditSupplierModal from "../../components/Dashboard/Supplier/EditSupplierModel";
import SuccessModal from "../../components/Dashboard/Supplier/SuccessModel";
import { API_ENDPOINTS } from "../../API";

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [newSupplier, setNewSupplier] = useState({
    company: "",
    verification_type: "",
    responsible_person: "",
    contact_number: "",
    companyAddress: "",
    supplier_code: ""
  });
  const [currentSupplier, setCurrentSupplier] = useState(null);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.SUPPLIERS);
      setSuppliers(response.data);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier({ ...newSupplier, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentSupplier({ ...currentSupplier, [name]: value });
  };

  const generateSupplierID = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const handleAddSupplier = async () => {
    const supplierData = {
      ...newSupplier,
      supplier_id: generateSupplierID(),
    };

    try {
      const response = await axios.post(API_ENDPOINTS.SUPPLIERS, supplierData);
      setSuppliers([...suppliers, response.data]);
      setModalIsOpen(false);
      setNewSupplier({ company: "", verification_type: "", responsible_person: "", contact_number: "", companyAddress: "", supplier_code: "" });
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error adding supplier:", error);
    }
  };

  const handleUpdateSupplier = async () => {
    try {
      const response = await axios.put(`${API_ENDPOINTS.SUPPLIERS}/${currentSupplier._id}`, currentSupplier);
      setSuppliers(suppliers.map(supplier => supplier._id === currentSupplier._id ? response.data : supplier));
      setEditModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error updating supplier:", error);
    }
  };

  const handleStatusChange = async (id) => {
    try {
      const supplier = suppliers.find(supplier => supplier._id === id);
      supplier.status = supplier.status === 'active' ? 'inactive' : 'active';
      const response = await axios.put(`${API_ENDPOINTS.SUPPLIERS}/${id}`, supplier);
      setSuppliers(suppliers.map(supplier => supplier._id === id ? response.data : supplier));
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleRemoveSupplier = async (id) => {
    try {
      await axios.delete(`${API_ENDPOINTS.SUPPLIERS}/${id}`);
      setSuppliers(suppliers.filter(supplier => supplier._id !== id));
    } catch (error) {
      console.error("Error removing supplier:", error);
    }
  };

  const openEditModal = (supplier) => {
    setCurrentSupplier(supplier);
    setEditModalIsOpen(true);
  };

  const filteredSuppliers = suppliers.filter((supplier) =>
    `${supplier.company} ${supplier.responsible_person}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Suppliers Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <div className="flex items-center w-full space-x-4">
          <input
            type="text"
            placeholder="Search by company or person"
            value={searchTerm}
            onChange={handleSearch}
            className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
          />
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setModalIsOpen(true)}
              className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              <FaPlus className="mr-2" /> Add Supplier
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        {/* Dynamic table content */}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Supplier ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Company
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Responsible Person
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Contact Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Company Address
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Verification Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Latest Contribution
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredSuppliers.map((supplier) => (
              <tr key={supplier._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                  {supplier.supplier_id}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {supplier.company}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {supplier.responsible_person}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {supplier.contact_number}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {supplier.companyAddress}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {supplier.verification_type}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <button
                    onClick={() => handleStatusChange(supplier._id)}
                    className={`px-4 py-2 rounded-md ${
                      supplier.status === 'active' ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'
                    } text-white`}
                  >
                    {supplier.status === 'active' ? 'Deactivate' : 'Activate'}
                  </button>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {supplier.latestContribution}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => openEditModal(supplier)}
                      className="flex items-center px-3 py-1 text-white bg-blue-500 rounded-md shadow-lg hover:bg-blue-600"
                    >
                      <FaEdit className="mr-2" /> Edit
                    </button>
                    <button
                      onClick={() => handleRemoveSupplier(supplier._id)}
                      className="flex items-center px-3 py-1 ml-2 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                    >
                      <FaTrashAlt className="mr-2" /> Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {/* Display message if no data */}
            {filteredSuppliers.length === 0 && (
              <tr>
                <td
                  colSpan="9"
                  className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap"
                >
                  No suppliers available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <AddSupplierModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        newSupplier={newSupplier}
        handleInputChange={handleInputChange}
        handleAddSupplier={handleAddSupplier}
      />

      <EditSupplierModal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        currentSupplier={currentSupplier}
        handleEditInputChange={handleEditInputChange}
        handleUpdateSupplier={handleUpdateSupplier}
      />

      <SuccessModal
        isOpen={successModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
      />
    </div>
  );
};

export default Suppliers;
