import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { API_ENDPOINTS } from '../API';

const LoginForm = () => {
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });
  const { login } = useAuth();

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_ENDPOINTS.LOGIN, loginData);
      if (response.data) {
        console.log('Login response data:', response.data);
        login({ user: response.data.user, token: response.data.token });
      }
    } catch (error) {
      if (error.response) {
        alert('Login failed: ' + error.response.data.message);
      } else if (error.request) {
        alert('No response from server');
      } else {
        alert('Error', error.message);
      }
    }
  };

  return (
    <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
      <h2 className="mb-6 text-3xl font-bold text-center text-gray-700">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="username" className="block py-3 text-sm font-medium text-gray-600">Username</label>
          <input
            type="text"
            id="username"
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
            placeholder="Enter your username"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block py-3 text-sm font-medium text-gray-600">Password</label>
          <input
            type="password"
            id="password"
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
            placeholder="Enter your password"
          />
        </div>
        <button type="submit" className="w-full py-3 mt-4 font-medium text-white uppercase bg-green-500 rounded-md shadow hover:bg-green-600 focus:outline-none">
          Log in
        </button>
        <p className="pt-5 text-center text-gray-600 text-md">Don't have an account? <a href="/register" className="text-blue-500">Register</a></p>
      </form>
    </div>
  );
};

export default LoginForm;
