import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

const AddReceivingModal = ({
  isOpen,
  onRequestClose,
  orderQuantity,
  handleAddReceiving,
  currentOrder // Ensure currentOrder is passed as a prop
}) => {
  const [returnQty, setReturnQty] = useState("");
  const [wastageQty, setWastageQty] = useState("");
  const [totalQuantity, setTotalQuantity] = useState(orderQuantity);
  const [password, setPassword] = useState("");

  useEffect(() => {
    // Calculate the total quantity based on the return and wastage
    const calculateTotalQuantity = () => {
      const returnVal = parseFloat(returnQty) || 0;
      const wastageVal = parseFloat(wastageQty) || 0;
      const total = parseFloat(orderQuantity) - returnVal - wastageVal;
      setTotalQuantity(total >= 0 ? total : 0); // Ensure it doesn't go below 0
    };
    calculateTotalQuantity();
  }, [returnQty, wastageQty, orderQuantity]);

  const handleSubmit = () => {
    if (!currentOrder || !currentOrder._id) {
      console.error("No valid order selected");
      return;
    }

    if (password === "0000") {
      handleAddReceiving({
        orderID: currentOrder._id,
        quantity: totalQuantity, // Use the calculated quantity
        wastage: wastageQty,
        return: returnQty,
        tiir: "OK", // Set TIIR to "OK" if the password matches
        receivestatus: "Pending"
      });
      onRequestClose(); // Close the modal after submission
    } else {
      alert("Incorrect password!");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Receiving Modal"
      className="relative w-full max-w-lg p-6 mx-auto bg-white rounded-lg shadow-lg"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">Add Receiving</h2>
        <button onClick={onRequestClose}>
          <FaTimes className="text-gray-600 hover:text-gray-900" />
        </button>
      </div>

      <form>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="return">
            Return
          </label>
          <input
            type="number"
            id="return"
            name="return"
            value={returnQty}
            onChange={(e) => setReturnQty(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="wastage">
            Wastage
          </label>
          <input
            type="number"
            id="wastage"
            name="wastage"
            value={wastageQty}
            onChange={(e) => setWastageQty(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="totalQuantity">
            Total Quantity
          </label>
          <input
            type="number"
            id="totalQuantity"
            name="totalQuantity"
            value={totalQuantity}
            disabled={true} // Make it read-only
            className="w-full px-4 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleSubmit}
            className="px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="px-4 py-2 font-bold text-gray-600 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddReceivingModal;
