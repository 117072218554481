import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTrashAlt, FaPrint } from 'react-icons/fa';
import CreateLabelModal from '../../components/Dashboard/CreateLabelModal'; // Import the correct modal component
import { API_ENDPOINTS } from '../../API';

const Labels = () => {
  const [labels, setLabels] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    fetchLabels();
  }, []);

  const fetchLabels = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const response = await axios.get(API_ENDPOINTS.LABELS, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLabels(response.data);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  const handleRemoveLabel = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.delete(`${API_ENDPOINTS.LABELS}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLabels(labels.filter(label => label._id !== id));
    } catch (error) {
      console.error('Error removing label:', error);
    }
  };

  const handlePrintLabel = (label) => {
    // Open a new window for printing
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Label</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              background-color: #f7f7f7;
            }
            .label-container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            }
            .label {
              border: 1px solid #ccc;
              padding: 20px;
              width: 300px;
              background-color: white;
              text-align: center;
            }
            .label h1 {
              font-size: 20px;
              margin-bottom: 10px;
              text-align: center;
            }
            .label p {
              margin: 5px 0;
              font-size: 14px;
            }
            .label .company {
              text-align: center;
              font-size: 16px;
              font-weight: bold;
            }
            .label .subheading {
              text-align: center;
              font-size: 12px;
              font-style: italic;
            }
            .label .details {
              margin-top: 20px;
            }
            .label .bottom-info {
              margin-top: 20px;
              font-size: 12px;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="label-container">
            <div class="label">
              <p class="company">MAARJI HERBAL PRODUCTS</p>
              <p class="subheading">Non-EU Agriculture Products of Srilanka</p>
              <hr />
              <h1>${label.productName}</h1>
              <div class="details">
                <p><strong>Type:</strong> ${label.type}</p>
                <p><strong>Quantity:</strong> ${label.quantity}</p>
                <p><strong>Grade:</strong> ${label.grade}</p>
                <p><strong>Ingredients:</strong> ${label.ingredients}</p>
                <!-- Add more fields as needed -->
              </div>
              <hr />
              <div class="bottom-info">
                <p>(KEEP AWAY FROM SUNLIGHT)</p>
                <p>SRI LANKA NATURAL SPICE AND HERBAL PRODUCTS</p>
                <p>Arangala, Naula, Matale, Sri Lanka</p>
                <p>Tel: +94 66 2246758 Fax: +94 66220652</p>
                <p>Email: info@maarjiherbalproducts.com</p>
                <p>(Organic ISO 22000 HACCP GMP Ayurvedic certified Company)</p>
                <p>Certified Organic By (CU):</p>
                <p>CU Number (CU 834510)</p>
                <p>CE Code (LK - BIO 149)</p>
              </div>
            </div>
          </div>
          <script>
            window.print();
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-2xl font-bold">Custom Label Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
        >
          <FaPlus className="mr-2" /> Create Label
        </button>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Type</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Product Name</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Quantity</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Grade</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {labels.map(label => (
              <tr key={label._id}>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{label.type}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{label.productName}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{label.quantity}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{label.grade}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handlePrintLabel(label)}
                      className="flex items-center px-3 py-1 text-white bg-blue-500 rounded-md shadow-lg hover:bg-blue-600"
                    >
                      <FaPrint className="mr-2" /> Print
                    </button>
                    <button
                      onClick={() => handleRemoveLabel(label._id)}
                      className="flex items-center px-3 py-1 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                    >
                      <FaTrashAlt className="mr-2" /> Remove
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {labels.length === 0 && (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                  No labels available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <CreateLabelModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        onSave={fetchLabels} // Refresh label list after adding
      />
    </div>
  );
};

export default Labels;
