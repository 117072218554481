import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../components/Dashboard/SideBar';
import Header from '../components/Dashboard/Header';
import FooterBar from '../components/Dashboard/FooterBar';
import Employees from './Dashboard/Employees';
import Labels from './Dashboard/Labels';
import Users from './Dashboard/Users';
import Farmers from './Dashboard/Farmers';
import Stores from './Dashboard/Stores';
import Products from './Dashboard/Products';
import Accounts from './Dashboard/Accounts';
import Jobs from './Dashboard/Jobs';
import MyProfile from './Dashboard/MyProfile';
import Notifications from './Dashboard/Notifications';
import Settings from './Dashboard/Settings';
import Help from './Dashboard/Help';
import Suppliers from './Dashboard/Supplier';
import Orders from './Dashboard/Orders';
import Receiving from './Dashboard/Receiving';
import Billing from './Dashboard/Billing';
import Buyers from './Dashboard/Buyers';

const Dashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split("/").filter(Boolean);
    const path = pathParts.length > 1 ? pathParts[1] : "";
    console.log("Full pathname:", location.pathname);
    console.log("Path parts:", pathParts);
    console.log("Current path segment:", path);

    switch (path.toLowerCase()) {  // Ensure paths are compared in lowercase
      case "employees":
        setSelectedComponent("Employees");
        break;
      case "suppliers":
        setSelectedComponent("Suppliers");
        break;
      case "buyers":
        setSelectedComponent("Buyers");
        break;
      case "orders":
        setSelectedComponent("Orders");
        break;
      case "receiving":
        setSelectedComponent("Receiving");
        break;
      case "billing":
        setSelectedComponent("Billing");
        break;
      case "labels":
        setSelectedComponent("Labels");
        break;
      case "users":
        setSelectedComponent("Users");
        break;
      case "farmers":
        setSelectedComponent("Farmers");
        break;
      case "stores":
        setSelectedComponent("Stores");
        break;
      case "products":
        setSelectedComponent("Products");
        break;
      case "accounts":
        setSelectedComponent("Accounts");
        break;
      case "jobs":
        setSelectedComponent("Jobs");
        break;
      case "profile":
        setSelectedComponent("MyProfile");
        break;
      case "notifications":
        setSelectedComponent("Notifications");
        break;
      case "settings":
        setSelectedComponent("Settings");
        break;
      case "help":
        setSelectedComponent("Help");
        break;
      default:
        setSelectedComponent(null);
    }
  }, [location]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="mx-4 mt-4 bg-white rounded-lg shadow">
        <Header onMenuItemClick={setSelectedComponent} />
      </div>
      <div className="flex">
        <div className="m-4">
          <Sidebar onMenuItemClick={setSelectedComponent} />
        </div>
        <main className="flex-1">
          <div className="w-full py-4 pr-4">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              {selectedComponent === 'Employees' && <Employees />}
              {selectedComponent === 'Suppliers' && <Suppliers />}
              {selectedComponent === 'Buyers' && <Buyers />}
              {selectedComponent === 'Orders' && <Orders />}
              {selectedComponent === 'Receiving' && <Receiving />}
              {selectedComponent === 'Billing' && <Billing />}
              {selectedComponent === 'Labels' && <Labels />}
              {selectedComponent === 'Users' && <Users />}
              {selectedComponent === 'Stores' && <Stores />}
              {selectedComponent === 'Products' && <Products />}
              {selectedComponent === 'Farmers' && <Farmers />}
              {selectedComponent === 'Accounts' && <Accounts />}
              {selectedComponent === 'Jobs' && <Jobs />}
              {selectedComponent === 'MyProfile' && <MyProfile />}
              {selectedComponent === 'Notifications' && <Notifications />}
              {selectedComponent === 'Settings' && <Settings />}
              {selectedComponent === 'Help' && <Help />}
              {!selectedComponent && <div>Please select an option from the sidebar.</div>}
            </div>
          </div>
        </main>
      </div>
      <FooterBar />
    </div>
  );
};

export default Dashboard;
