import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import AddFarmerModal from "../../components/Dashboard/Farmer/AddFarmerModel";
import EditFarmerModal from "../../components/Dashboard/Farmer/EditFarmerModel";
import { API_ENDPOINTS } from "../../API";
import SuccessModal from "../../components/Dashboard/Farmer/SuccessModel";

const Farmers = () => {
  const [farmers, setFarmers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [newFarmer, setNewFarmer] = useState({
    firstName: "",
    secondName: "",
    contactNumber: "",
    NIC: "",
    verification_type: ""
  });
  const [currentFarmer, setCurrentFarmer] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [farmersPerPage] = useState(10); // Set number of farmers per page

  const fetchFarmers = useCallback(async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.FARMERS);
      setFarmers(response.data || []);
    } catch (error) {
      console.error("Error fetching farmers:", error);
      setFarmers([]); // Fallback to empty array in case of error
    }
  }, []);

  useEffect(() => {
    fetchFarmers();
  }, [fetchFarmers]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFarmer({ ...newFarmer, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentFarmer({ ...currentFarmer, [name]: value });
  };

  const generateFarmerID = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const handleAddFarmer = async () => {
    const farmerData = {
      ...newFarmer,
      farmerID: generateFarmerID(),
    };

    try {
      const response = await axios.post(API_ENDPOINTS.FARMERS, farmerData);
      setFarmers([...farmers, response.data]);
      setModalIsOpen(false);
      setNewFarmer({ firstName: "", secondName: "", contactNumber: "", NIC: "", verification_type: "" });
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error adding farmer:", error);
    }
  };

  const handleUpdateFarmer = async () => {
    try {
      const response = await axios.put(`${API_ENDPOINTS.FARMERS}/${currentFarmer._id}`, currentFarmer);
      setFarmers(farmers.map(farmer => farmer._id === currentFarmer._id ? response.data : farmer));
      setEditModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {
      console.error("Error updating farmer:", error);
    }
  };

  const handleStatusChange = async (id) => {
    try {
      const farmer = farmers.find(farmer => farmer._id === id);
      farmer.status = farmer.status === 'active' ? 'inactive' : 'active';
      const response = await axios.put(`${API_ENDPOINTS.FARMERS}/${id}`, farmer);
      setFarmers(farmers.map(farmer => farmer._id === id ? response.data : farmer));
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  const handleRemoveFarmer = async (id) => {
    try {
      await axios.delete(`${API_ENDPOINTS.FARMERS}/${id}`);
      setFarmers(farmers.filter(farmer => farmer._id !== id));
    } catch (error) {
      console.error("Error removing farmer:", error);
    }
  };

  const openEditModal = (farmer) => {
    setCurrentFarmer(farmer);
    setEditModalIsOpen(true);
  };

  const filteredFarmers = farmers.filter((farmer) =>
    `${farmer.firstName} ${farmer.secondName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastFarmer = currentPage * farmersPerPage;
  const indexOfFirstFarmer = indexOfLastFarmer - farmersPerPage;
  const currentFarmers = filteredFarmers.slice(indexOfFirstFarmer, indexOfLastFarmer);

  const totalPages = Math.ceil(filteredFarmers.length / farmersPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-3xl font-bold">Farmers Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <div className="flex items-center w-full space-x-4">
          <input
            type="text"
            placeholder="Search by full name"
            value={searchTerm}
            onChange={handleSearch}
            className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
          />
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setModalIsOpen(true)}
              className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              <FaPlus className="mr-2" /> Add Farmer
            </button>
          </div>
        </div>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Farmer ID</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Full Name</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Contact Number</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Volume (Kg)</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Yield Status</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Verification Type</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Latest Contribution</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentFarmers.map((farmer) => (
              <tr key={farmer._id}>
                <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{farmer.maargi_id}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{`${farmer.firstName} ${farmer.secondName}`}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{farmer.contactNumber}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{farmer.volume}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{farmer.yieldStatus}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{farmer.verification_type}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <button
                    onClick={() => handleStatusChange(farmer._id)}
                    className={`px-4 py-2 rounded-md ${farmer.status === 'active' ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'} text-white`}
                  >
                    {farmer.status === 'active' ? 'Deactivate' : 'Activate'}
                  </button>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {new Date(farmer.latestContribution).toLocaleDateString()} {/* Format the date */}
                </td>
                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                  <button
                    onClick={() => openEditModal(farmer)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleRemoveFarmer(farmer._id)}
                    className="ml-3 text-red-600 hover:text-red-800"
                  >
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 text-gray-800 bg-gray-300 rounded-md hover:bg-gray-400"
        >
          Previous
        </button>
        <span className="px-4 py-2 text-gray-700">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 text-gray-800 bg-gray-300 rounded-md hover:bg-gray-400"
        >
          Next
        </button>
      </div>

      <AddFarmerModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)} 
        handleAddFarmer={handleAddFarmer} 
        newFarmer={newFarmer} 
        handleInputChange={handleInputChange} 
      />

      <EditFarmerModal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        currentFarmer={currentFarmer}  
        handleEditInputChange={handleEditInputChange}
        handleUpdateFarmer={handleUpdateFarmer}
      />

      <SuccessModal
        isOpen={successModalIsOpen}
        onClose={() => setSuccessModalIsOpen(false)}
      />
    </div>
  );
};

export default Farmers;
