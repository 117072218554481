import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  FaSignOutAlt,
  FaUsers,
  FaStore,
  FaClipboardCheck,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { GiFarmer, GiHumanPyramid } from "react-icons/gi";
import { MdOutlineNewLabel } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { CiSquarePlus } from "react-icons/ci";
import { FaBoxesPacking } from "react-icons/fa6";


const Sidebar = ({ onMenuItemClick }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isSuppliersOpen, setIsSuppliersOpen] = useState(false);
  const [isHROpen, setIsHROpen] = useState(false);
  const [isProductionOpen, setIsProductionOpen] = useState(false);

  const handleNavigation = (component, path) => {
    console.log(`Navigating to ${path} and setting component to ${component}`); // Debugging line
    onMenuItemClick(component);
    navigate(path);
  };

  return (
    <aside className="w-64 text-gray-800 bg-green-500 rounded-lg shadow-lg">
      <div className="px-4 py-6">
        <ul className="space-y-3">
          <li>
            <button
              onClick={() => handleNavigation("Home", "/dashboard")}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <IoHomeOutline className="mr-3" /> Home
            </button>
          </li>
          {/* Suppliers with Nested Menu */}
          <li>
            <button
              onClick={() => setIsSuppliersOpen(!isSuppliersOpen)}
              className="flex items-center justify-between w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <span className="flex items-center">
                <GoOrganization className="mr-3" /> Suppliers
              </span>
              {isSuppliersOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isSuppliersOpen && (
              <ul className="ml-6 space-y-2">
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Farmers", "/dashboard/farmers")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <GiFarmer className="mr-3" /> Farmers
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Traders", "/dashboard/suppliers")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <GiHumanPyramid className="mr-3" /> Traders
                  </button>
                </li>
              </ul>
            )}
          </li>
          {/* Suppliers with Nested Menu */}
          <li>
            <button
              onClick={() => handleNavigation("Buyers", "/dashboard/buyers")}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <FaUsers className="mr-3" /> Buyers
            </button>
          </li>

          {/* HR with nested menu */}
          <li>
            <button
              onClick={() => setIsHROpen(!isHROpen)}
              className="flex items-center justify-between w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <span className="flex items-center">
                <GoOrganization className="mr-3" /> HR
              </span>
              {isHROpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isHROpen && (
              <ul className="ml-6 space-y-2">
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Employees", "/dashboard/employees")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <GiHumanPyramid className="mr-3" /> Employees
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Users", "/dashboard/users")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <FaUsers className="mr-3" /> Users
                  </button>
                </li>
              </ul>
            )}
          </li>
          {/* HR with nested menu */}

          {/* Production with Nested Menu */}

          <li>
            <button
              onClick={() => setIsProductionOpen(!isProductionOpen)}
              className="flex items-center justify-between w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <span className="flex items-center">
                <GoOrganization className="mr-3" /> Production
              </span>
              {isProductionOpen ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {isProductionOpen && (
              <ul className="ml-6 space-y-2">
                {/* Orders */}
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Products", "/dashboard/products")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <FaBoxesPacking className="mr-3" /> Products
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Orders", "/dashboard/orders")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <CiSquarePlus className="mr-3" /> Orders
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      handleNavigation("Receiving", "/dashboard/receiving")
                    }
                    className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
                  >
                    <GiFarmer className="mr-3" /> Receiving
                  </button>
                </li>
                                
              </ul>
            )}
          </li>
          <li>
            <button
              onClick={() => handleNavigation("Billing", "/dashboard/billing")}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <GiFarmer className="mr-3" /> Billing
            </button>
          </li>
          {/* Labels */}
          <li>
            <button
              onClick={() => handleNavigation("Labels", "/dashboard/labels")}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <MdOutlineNewLabel className="mr-3" /> Labels
            </button>
          </li>
          <li>
            <button
              onClick={() => handleNavigation("Jobs", "/dashboard/jobs")}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <FaClipboardCheck className="mr-3" /> Jobs
            </button>
          </li>

          <li>
            <button
              onClick={() => handleNavigation("Stores", "/dashboard/stores")}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <FaStore className="mr-3" /> Stores
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                handleNavigation("Accounts", "/dashboard/accounts")
              }
              className="flex items-center justify-start w-full p-3 text-lg font-semibold transition duration-300 ease-in-out rounded-md hover:bg-green-600 hover:text-white focus:outline-none focus:bg-green-600 focus:text-white"
            >
              <FaUsers className="mr-3" /> Finance
            </button>
          </li>
          <li>
            <button
              onClick={logout}
              className="flex items-center justify-start w-full p-3 text-lg font-semibold text-white transition duration-300 ease-in-out rounded-md hover:bg-red-700 hover:text-white focus:outline-none focus:text-white"
            >
              <FaSignOutAlt className="mr-3" /> Log Out
            </button>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
