import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import CreateJobModal from '../../components/Dashboard/Jobs/CreateJobModel'; // Import the correct modal component
import EditJobModal from '../../components/Dashboard/Jobs/EditJobModel'; // Import the correct modal component

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false); // State for create modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal
  const [selectedJob, setSelectedJob] = useState(null); // State for selected job to edit

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }
      const response = await axios.get('https://maarji-server.onrender.com/api/jobs', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setJobs(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredJobs = jobs.filter(job => 
    (job.companyName || job.BuyerName).toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRemoveJob = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      await axios.delete(`https://maarji-server.onrender.com/api/jobs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setJobs(jobs.filter(job => job._id !== id));
    } catch (error) {
      console.error('Error removing job:', error);
    }
  };

  const handleEditJob = (job) => {
    setSelectedJob(job);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedJob(null);
  };

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <h1 className="mb-4 text-2xl font-bold">Job Management</h1>
      <div className="flex items-center justify-between p-4 mb-4 bg-gray-200 rounded-lg shadow-md">
        <input
          type="text"
          placeholder="Search by Buyer name"
          value={searchTerm}
          onChange={handleSearch}
          className="flex-grow px-4 py-2 mr-4 border border-gray-300 rounded-md"
        />
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
        >
          <FaPlus className="mr-2" /> Create Job
        </button>
      </div>

      <div className="p-6 bg-white rounded-lg shadow-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Buyer Name</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">PO No</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">JC No</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Item</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">B No</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Qty</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Cut Size</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Order Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Requested Date</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Lot Nos</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredJobs.map(job => (
              <tr key={job._id}>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.companyName || job.BuyerName}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.poNo}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.jobId}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.item}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.bNo}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.orderQty}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.cutSize}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(job.orderDate).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{new Date(job.requestedDate).toLocaleDateString()}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.lotNos.join(', ')}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{job.status}</td>
                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleRemoveJob(job._id)}
                      className="flex items-center px-3 py-1 text-white bg-red-500 rounded-md shadow-lg hover:bg-red-600"
                    >
                      <FaTrashAlt className="mr-2" /> Remove
                    </button>
                    <button
                      onClick={() => handleEditJob(job)}
                      className="flex items-center px-3 py-1 text-white bg-blue-500 rounded-md shadow-lg hover:bg-blue-600"
                    >
                      <FaPencilAlt className="mr-2" /> Edit
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {filteredJobs.length === 0 && (
              <tr>
                <td colSpan="12" className="px-6 py-4 text-sm font-medium text-center text-gray-500 whitespace-nowrap">
                  No jobs available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <CreateJobModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        onSave={fetchJobs} // Refresh job list after adding
      />

      {selectedJob && (
        <EditJobModal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          job={selectedJob}
          onSave={fetchJobs} // Refresh job list after editing
        />
      )}
    </div>
  );
};

export default Jobs;
