// components/Dashboard/Supplier/EditSupplierModal.js
import React from "react";
import Modal from "react-modal";

const EditSupplierModal = ({ isOpen, onRequestClose, currentSupplier, handleEditInputChange, handleUpdateSupplier }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Edit Supplier"
    className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold">Edit Supplier</h2>
      {currentSupplier && (
        <form className="space-y-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Company</label>
              <input
                type="text"
                name="company"
                value={currentSupplier.company}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Verification Type</label>
              <select
                name="verification_type"
                value={currentSupplier.verification_type}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select Verification Type</option>
                <option value="Organic">Organic</option>
                <option value="Non-Organic">Non-Organic</option>
                <option value="Fair Trade">Fair Trade</option>
                <option value="Rainforest">Rainforest</option>
              </select>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Responsible Person</label>
              <input
                type="text"
                name="responsible_person"
                value={currentSupplier.responsible_person}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Contact Number</label>
              <input
                type="text"
                name="contact_number"
                value={currentSupplier.contact_number}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Company Address</label>
              <input
                type="text"
                name="companyAddress"
                value={currentSupplier.companyAddress}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Supplier Code</label>
              <input
                type="text"
                name="supplier_code"
                value={currentSupplier.supplier_code}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={handleUpdateSupplier}
              className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              Update Supplier
            </button>
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  </Modal>
);

export default EditSupplierModal;
