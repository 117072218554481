import React from "react";
import Modal from "react-modal";

// Set the app element to prevent screen readers from reading background content when the modal is open.
Modal.setAppElement('#root'); // Ensure this is called

const AddFarmerModal = ({
  isOpen,
  onRequestClose, // This is correct
  newFarmer, // This is correct
  handleInputChange, // This is correct
  handleAddFarmer
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Add Farmer"
    className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold">Add New Farmer</h2>
      <form className="space-y-4" onSubmit={handleAddFarmer}>
        {/* <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">First Name</label>
          <input
            type="text"
            name="firstName"
            value={newFarmer.firstName}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          //required
          />
        </div> */}
        {/* <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Second Name</label>
          <input
            type="text"
            name="secondName"
            value={newFarmer.secondName}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          //required
          />
        </div> */}
        {/* <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Contact Number</label>
          <input
            type="text"
            name="contactNumber"
            value={newFarmer.contactNumber}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          //required
          />
        </div> */}
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Maarji ID</label>
          <input
            type="text"
            name="maargi_id"
            value={newFarmer.maargi_id}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">City</label>
          <input
            type="text"
            name="city"
            value={newFarmer.city}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        {/* <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">NIC</label>
          <input
            type="text"
            name="NIC"
            value={newFarmer.NIC}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          //required
          />
        </div> */}
        {/* <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Verification Type</label>
          <select
            name="verification_type"
            value={newFarmer.verification_type}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md"
          //required
          >
            <option value="">Select Verification Type</option>
            <option value="Organic">Organic</option>
            <option value="Non-Organic">Non-Organic</option>
            <option value="Fair Trade">Fair Trade</option>
            <option value="Rainforest">Rainforest</option>
          </select>
        </div> */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
          >
            Add Farmer
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="px-4 py-2 ml-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </Modal>
);

export default AddFarmerModal;
