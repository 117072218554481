import React from "react";
import Modal from "react-modal";

const EditOrderModal = ({ isOpen, onRequestClose, currentOrder, handleEditInputChange, handleUpdateOrder }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Edit Order"
    className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50"
  >
    <div className="w-full max-w-3xl p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold">Edit Order</h2>
      {currentOrder && (
        <form className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Supplier/Farmer ID</label>
              <input
                type="text"
                name="supplierID"
                value={currentOrder.supplierID}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
                readOnly
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Order Item</label>
              <input
                type="text"
                name="orderitem"
                value={currentOrder.orderitem}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Order Date</label>
              <input
                type="date"
                name="orderdate"
                value={new Date(currentOrder.orderdate).toISOString().split('T')[0]}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                name="quantity"
                value={currentOrder.quantity}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Receive Date</label>
              <input
                type="date"
                name="receive_date"
                value={new Date(currentOrder.receive_date).toISOString().split('T')[0]}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Receiving Point</label>
              <input
                type="text"
                name="receiving_point"
                value={currentOrder.receiving_point}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Order Type</label>
              <input
                type="text"
                name="ordertype"
                value={currentOrder.ordertype}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">Order Status</label>
              <input
                type="text"
                name="orderstatus"
                value={currentOrder.orderstatus}
                onChange={handleEditInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={handleUpdateOrder}
              className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              Update Order
            </button>
            <button
              type="button"
              onClick={onRequestClose}
              className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  </Modal>
);

export default EditOrderModal;
