import React from "react";
import LoginForm from "../components/LoginForm";
import backgroundImage from "../assets/images/bg_farmer.jpg"; // Ensure you have an image here
import logo from "../assets/images/logo_zylonlabs.png"; // Make sure the logo image is in your assets/images folder

function App() {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Left Pane */}
      <div className="relative w-1/2 h-full">
        {/* Background image with gradient overlay */}
        <div
          className="absolute inset-0 bg-center bg-cover"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-r from-green-500/50 to-transparent"></div>

          {/* Logo button in the top left corner */}
          <a href="https://zylonlabs.com" target="_blank" rel="noreferrer">
          <button className="absolute p-2 bg-white shadow-xl top-4 left-4 focus:outline-none rounded-xl">
            <img src={logo} alt="Logo" className="w-12 h-12" />{" "}
            {/* Adjust size as needed */}
          </button>
          </a>
        </div>
      </div>
      {/* Right Pane */}
      <div className="flex items-center justify-center w-1/2 h-full">
        <LoginForm />
        {/* Footer */}
        <div className="absolute text-center text-black bottom-4">
          <p className="text-sm">
            <a href="https://zylonlabs.com" target="_blank" rel="noreferrer">
            Zylon Labs </a>
            © 2024 - All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
