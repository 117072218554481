import React from "react";

const Accounts = () => {
    return (
        <div>
        <h1>Accounts Page</h1>
        </div>
    );
    }

export default Accounts;