import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSave, FaTimes } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../API';

const EditUserPopup = ({ user, onClose, onSave }) => {
  const [editUser, setEditUser] = useState(user || {});
  const [profileImage, setProfileImage] = useState(null);
  const [existingImage, setExistingImage] = useState(null);  // State for the existing profile image
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (user && user._id) {
      setEditUser({ ...user });
      setError('');

      // Fetch existing profile image
      const fetchProfileImage = async () => {
        try {
          const response = await axios.get(`${API_ENDPOINTS.USERS}/${user._id}/profile-image`, {
            responseType: 'blob',  // Ensure we get the image as a blob
          });

          // Create a URL for the fetched image blob
          const imageUrl = URL.createObjectURL(response.data);
          setExistingImage(imageUrl);
        } catch (error) {
          console.error('Error fetching profile image:', error);
          // If the image is not found, we may choose not to set any error
        }
      };

      fetchProfileImage();
    } else {
      setError('User data is not available.');
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
    setExistingImage(null); // Hide existing image preview when a new image is selected
  };

  const handleProfileUpdate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }

    if (!editUser._id) {
      setError('User ID is missing.');
      return;
    }

    try {
      setLoading(true);

      // Update user data excluding the image
      const { firstname, lastname, contact_number, role, address, city, state, country, zip } = editUser;
      const updateData = { firstname, lastname, contact_number, role, address, city, state, country, zip };

      await axios.put(`${API_ENDPOINTS.USERS}/${editUser._id}`, updateData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // If a new profile image is selected, upload it
      if (profileImage) {
        const formData = new FormData();
        formData.append('profile_image', profileImage);

        await axios.post(`${API_ENDPOINTS.USERS}/${editUser._id}/upload-image`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      setSuccess('User updated successfully');
      setLoading(false);
      onSave(editUser); // Pass the updated user object to onSave
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  if (!user || !user._id) {
    return null; // Do not render the component if user data is not available
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="relative w-full max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
        <button onClick={onClose} className="absolute text-gray-600 top-2 right-2 hover:text-gray-900">
          <FaTimes size={24} />
        </button>
        <h2 className="mb-4 text-xl font-semibold">Edit User</h2>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-semibold">Account Details</h2>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">First Name</label>
              <input
                type="text"
                name="firstname"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.firstname || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastname"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.lastname || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Contact Number</label>
              <input
                type="text"
                name="contact_number"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.contact_number || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Role</label>
              <select
                name="role"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.role || ''}
                onChange={handleInputChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="production_manager">Production Manager</option>
                <option value="production_association">Production Association</option>
                <option value="it_officer">It Officer</option>
                <option value="supervisor">Supervisor</option>
                <option value="qa_assistant">Quality Assurance Assistant</option>
                <option value="maintrace">Maintrace</option>
                <option value="driver">Driver</option>
                <option value="sales_and_marketing">Sales and Marketing</option>
                <option value="customer_care_executive">Customer Care executive</option>
                <option value="account_assistant">Account assistant</option>
                <option value="field_assistant_officer">Field assistant Officer</option>
                <option value="purchasing_assistant">Purchasing assistant</option>
                <option value="hr_assistant">HR Assistant</option>
                <option value="finance_assistant">Finance Assistant</option>
                <option value="traserbility_officer">Traserbility Officer</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Profile Image</label>
              <input
                type="file"
                name="profile_image"
                accept="image/*"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                onChange={handleImageChange}
              />
              {profileImage ? (
                <img src={URL.createObjectURL(profileImage)} alt="Preview" className="w-20 h-20 mt-4 rounded-full" />
              ) : existingImage ? (
                <img src={existingImage} alt="Existing Profile" className="w-20 h-20 mt-4 rounded-full" />
              ) : null}
            </div>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-semibold">Address</h2>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Address</label>
              <input
                type="text"
                name="address"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.address || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">City</label>
              <input
                type="text"
                name="city"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.city || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">State</label>
              <input
                type="text"
                name="state"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.state || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Country</label>
              <input
                type="text"
                name="country"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.country || ''}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Zip</label>
              <input
                type="text"
                name="zip"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={editUser.zip || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button onClick={handleProfileUpdate} className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600">
            <FaSave className="mr-2" /> Update User
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUserPopup;
