import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaSave, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../API';

const CreateJobModal = ({ isOpen, onRequestClose, onSave }) => {
  const [newJob, setNewJob] = useState({
    buyerId: '',
    poNo: '',
    item: '',
    bNo: '',
    orderQty: '',
    cutSize: '',
    orderDate: '',
    requestedDate: '',
    lotNos: '',
    status: 'started' // Default status
  });
  const [buyers, setBuyers] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchBuyers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found');
          return;
        }

        const response = await axios.get(API_ENDPOINTS.BUYERS, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setBuyers(response.data);
      } catch (error) {
        setError(error.response ? error.response.data : error.message);
      }
    };

    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found');
          return;
        }

        const response = await axios.get(API_ENDPOINTS.PRODUCTS, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setProducts(response.data);
      } catch (error) {
        setError(error.response ? error.response.data : error.message);
      }
    };

    if (isOpen) {
      fetchBuyers();
      fetchProducts();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewJob({ ...newJob, [name]: value });
  };

  const handleCreateJob = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      setLoading(true);

      // Convert lotNos to an array if necessary
      const jobData = {
        ...newJob,
        lotNos: newJob.lotNos.split(',').map(lot => lot.trim())
      };

      const response = await axios.post(API_ENDPOINTS.JOBS, jobData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setSuccess('Job created successfully');
      setLoading(false);
      onSave(); // Refresh the job list
      onRequestClose(); // Close the modal
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Create Job Modal"
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
    >
      <div className="relative w-full max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
        <button
          onClick={onRequestClose}
          className="absolute text-gray-600 top-2 right-2 hover:text-gray-900"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="mb-4 text-xl font-semibold">Create New Job</h2>
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-semibold">Job Details</h2>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Company Name</label>
              <select
                name="buyerId"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.buyerId}
                onChange={handleInputChange}
              >
                <option value="">Select a Buyer</option>
                {buyers.map((buyer) => (
                  <option key={buyer._id} value={buyer._id}>
                    {buyer.company}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">PO No</label>
              <input
                type="text"
                name="poNo"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.poNo}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Item</label>
              <select
                name="item"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.item}
                onChange={handleInputChange}
              >
                <option value="" disabled>Select an item</option>
                {products.map((product) => (
                  <option key={product._id} value={product.name}>
                    {product.name} ({product.type})
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">B No</label>
              <input
                type="text"
                name="bNo"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.bNo}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h2 className="mb-4 text-xl font-semibold">Order Details</h2>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Order Qty</label>
              <input
                type="number"
                name="orderQty"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.orderQty}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Cut Size</label>
              <input
                type="text"
                name="cutSize"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.cutSize}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Order Date</label>
              <input
                type="date"
                name="orderDate"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.orderDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Requested Date</label>
              <input
                type="date"
                name="requestedDate"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.requestedDate}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1 font-semibold text-gray-700">Lot Nos</label>
              <input
                type="text"
                name="lotNos"
                className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
                value={newJob.lotNos}
                onChange={handleInputChange}
              />
            </div>
            {/* Hidden input field for status */}
            <input
              type="hidden"
              name="status"
              value="started"
            />
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={onRequestClose}
            className="px-4 py-2 mr-2 bg-gray-300 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateJob}
            className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600"
          >
            <FaSave className="mr-2" /> Create Job
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateJobModal;
