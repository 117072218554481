import React from "react";
import RegisterForm from "../components/RegisterForm";
import backgroundImage from "../assets/images/bg_farmer.jpg"; // Adjust the path as necessary
import logo from "../assets/images/logo_zylonlabs.png"; // Reuse the same logo or a different one

function Register() {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Left Pane with image */}
      <div className="relative w-1/2 h-full">
        <div className="absolute inset-0 bg-center bg-cover" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="absolute inset-0 bg-gradient-to-r from-green-500/50 to-transparent"></div>
          <a href="https://zylonlabs.com" target="_blank" rel="noreferrer">
            <button className="absolute p-2 bg-white shadow-xl top-4 left-4 focus:outline-none rounded-xl">
              <img src={logo} alt="Logo" className="w-12 h-12" />
            </button>
          </a>
        </div>
      </div>
      {/* Right Pane with registration form */}
      <div className="flex items-center justify-center w-1/2 h-full">
        <RegisterForm />
        <div className="absolute text-center text-black bottom-4">
          <p className="text-sm">
            <a href="https://zylonlabs.com" target="_blank" rel="noreferrer">
              Zylon Labs
            </a> © 2024 - All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
