// ApprovalModal.js
import React, { useState } from "react";
import Modal from "react-modal";

const ApprovalModal = ({ isOpen, onRequestClose, onApprove, onUnapprove, orderStatus }) => {
  const [key, setKey] = useState("");

  const handleApprove = () => {
    if (key === "0000") {
      if (typeof onApprove === 'function') {
        onApprove();
      } else {
        console.error('onApprove is not a function');
      }
      setKey(""); // Clear the input box
      onRequestClose();
    } else {
      alert("Invalid key");
    }
  };

  const handleUnapprove = () => {
    if (key === "0000") {
      if (typeof onUnapprove === 'function') {
        onUnapprove();
      } else {
        console.error('onUnapprove is not a function');
      }
      setKey(""); // Clear the input box
      onRequestClose();
    } else {
      alert("Invalid key");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Approval"
      className="fixed inset-0 flex items-center justify-center p-4 bg-gray-800 bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-2xl font-bold">Approve/Unapprove Order</h2>
        <input
          type="text"
          placeholder="Secret key"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md"
        />
        <div className="flex justify-end mt-4">
          {orderStatus === 'Pending' && (
            <button
              type="button"
              onClick={handleApprove}
              className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              Approve
            </button>
          )}
          {orderStatus === 'Approved' && (
            <button
              type="button"
              onClick={handleUnapprove}
              className="px-4 py-2 ml-2 text-white bg-yellow-500 rounded-md hover:bg-yellow-600"
            >
              Unapprove
            </button>
          )}
          <button
            type="button"
            onClick={onRequestClose}
            className="px-4 py-2 ml-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ApprovalModal;
