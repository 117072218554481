import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaSave, FaTimes } from "react-icons/fa";
import axios from "axios";
import { API_ENDPOINTS } from "../../../API";

const CreateBillingModal = ({
  isOpen,
  onRequestClose,
  currentReceiving,
  onSave,
}) => {
  const [pricePerUnit, setPricePerUnit] = useState("0");
  const [taxRate, setTaxRate] = useState("0");
  const [otherCosts, setOtherCosts] = useState("0");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (isOpen) {
      setPricePerUnit("0");
      setTaxRate("0");
      setOtherCosts("0");
      setError("");
      setSuccess("");
    }
  }, [isOpen]);

  const calculateFinalAmount = () => {
    const quantity = currentReceiving?.quantity || 0;
    const pricePerUnitNum = parseFloat(pricePerUnit) || 0;
    const taxRateNum = parseFloat(taxRate) || 0;
    const otherCostsNum = parseFloat(otherCosts) || 0;

    const totalAmount = pricePerUnitNum * quantity;
    const taxAmount = totalAmount * (taxRateNum / 100);
    const finalAmount = totalAmount + taxAmount - otherCostsNum;

    return finalAmount.toFixed(2); // Keep it to two decimal places for clarity
  };

  const handleCreateBilling = async () => {
    if (!currentReceiving) {
      setError("No receiving information available.");
      return;
    }

    // Validate numeric inputs
    if (isNaN(parseFloat(pricePerUnit)) || isNaN(parseFloat(taxRate)) || isNaN(parseFloat(otherCosts))) {
      setError("Invalid numeric input");
      return;
    }

    const billingData = {
      receivingID: currentReceiving._id,
      pricePerUnit: parseFloat(pricePerUnit),
      taxRate: parseFloat(taxRate),
      otherCosts: parseFloat(otherCosts),
    };

    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    try {
      setLoading(true);
      console.log("Sending data:", billingData); // Debugging request data

      const response = await axios.post(API_ENDPOINTS.BILLINGS, billingData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Response:", response.data); // Debugging response data

      setSuccess("Billing created successfully");
      onSave(); // Refresh or update billing list
      onRequestClose(); // Close the modal
    } catch (error) {
      console.error("Error creating billing:", error.response || error.message);
      setError("Failed to create billing: " + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Create Billing Modal"
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
    >
      <div className="relative w-full max-w-md p-6 mx-auto bg-white rounded-lg shadow-lg">
        <button
          onClick={onRequestClose}
          className="absolute text-gray-600 top-2 right-2 hover:text-gray-900"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="mb-4 text-xl font-semibold">Create Billing</h2>
        {loading && <p className="text-blue-500">Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="grid grid-cols-1 gap-6">
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">
              Price Per Unit
            </label>
            <input
              type="text"
              value={pricePerUnit}
              onChange={(e) => setPricePerUnit(e.target.value)}
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">
              Tax Rate (%)
            </label>
            <input
              type="text"
              value={taxRate}
              onChange={(e) => setTaxRate(e.target.value)}
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">
              Other Costs
            </label>
            <input
              type="text"
              value={otherCosts}
              onChange={(e) => setOtherCosts(e.target.value)}
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">
              Final Amount
            </label>
            <input
              type="text"
              value={calculateFinalAmount()}
              readOnly
              className="w-full p-2 bg-gray-100 border-b-2 border-gray-300"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleCreateBilling}
              className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600"
            >
              <FaSave className="mr-2" /> Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBillingModal;
