import React from 'react';
import Barcode from 'react-barcode';
import { API_ENDPOINTS } from '../../../API';

const IDCard = ({ user }) => {
  // Construct the profile image URL
  const profileImageUrl = user._id 
    ? `${API_ENDPOINTS.USERS}/${user._id}/profile-image`
    : 'https://via.placeholder.com/150';

  // Utility function to format the role
  const formatRole = (role) => {
    if (!role) return '';
    return role
      .split('_') // Split by underscore
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' '); // Join back with a space
  };

  return (
    <div className="relative w-full max-w-sm p-4 bg-white border border-gray-300 rounded-lg shadow-md print:block">
      {/* Profile Image */}
      <div className="absolute w-[80px] h-[80px] overflow-hidden bg-gray-200 rounded-full shadow-lg top-4 right-4">
        <img 
          src={profileImageUrl} 
          alt="User Profile" 
          className="object-cover w-full h-full"
        />
      </div>

      {/* User Information */}
      <div className="flex flex-col space-y-1 mt-4 mr-[100px]">
        <h2 className="text-lg font-bold leading-tight text-gray-800">
          {user.firstname} <br /> {user.lastname}
        </h2>
        <p className="text-sm font-semibold text-gray-600">{formatRole(user.role)}</p>
        <div className="pt-2 mt-1 border-t border-gray-300">
          <p className="text-xs text-gray-700"><strong>Email:</strong> {user.email}</p>
          <p className="text-xs text-gray-700"><strong>Contact:</strong> {user.contact_number}</p>
          <p className="mt-1 text-xs text-gray-500">Issued: {new Date().toLocaleDateString()}</p>
        </div>
      </div>

      {/* Barcode for User ID */}
      <div className="flex justify-start mt-4">
        <Barcode 
          value={user._id} 
          format="CODE128" 
          width={1.2} 
          height={50} 
          displayValue={false} 
          background="transparent"
          lineColor="#000"
        />
      </div>
    </div>
  );
};

export default IDCard;
