import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCamera, FaSave, FaLock } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../API';

const MyProfile = () => {
  const [user, setUser] = useState({
    profilePicture: 'https://via.placeholder.com/150',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    role: '',
    contact_number: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: ''
  });

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found');
        return;
      }
  
      try {
        setLoading(true);
        const response = await axios.get(API_ENDPOINTS.PROFILE, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 401) {
          setError('Invalid token. Please log in again.');
          localStorage.removeItem('token');
        }
        setLoading(false);
      }
    };
  
    fetchProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleProfileUpdate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      setLoading(true);
      await axios.put(API_ENDPOINTS.PROFILE, user, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setSuccess('Profile updated successfully');
      setLoading(false);
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      setLoading(true);
      await axios.post(API_ENDPOINTS.CHANGE_PASSWORD,
        { currentPassword, newPassword },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSuccess('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setLoading(false);
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem('token');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(API_ENDPOINTS.UPLOAD_PROFILE_IMAGE, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setUser({ ...user, profilePicture: response.data.fileUrl });
      setSuccess('Profile picture updated successfully');
      setLoading(false);
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };

  return (
    <div className="container p-6 mx-auto bg-gray-100">
      <div className="p-6 mb-6 bg-white rounded-lg shadow-lg">
        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
        <div className="flex items-center">
          <div className="relative group">
            <img src={user.profilePicture} alt="Profile" className="border-4 border-white rounded-full shadow-lg w-36 h-36" />
            <label className="absolute bottom-0 right-0 p-2 text-white transition-opacity bg-gray-800 rounded-full opacity-0 cursor-pointer group-hover:opacity-100">
              <input
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
              <FaCamera />
            </label>
          </div>
          <div className="ml-6">
            <h1 className="text-3xl text-gray-900">{user.firstname} {user.lastname}</h1>
            <p className="text-xl font-bold text-gray-600">@{user.username}</p>
            <p className="text-gray-600">{user.email}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h2 className="mb-4 text-xl font-semibold">Account Details</h2>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Role</label>
            <p className="p-2 bg-gray-100 rounded-md shadow-inner">{user.role}</p>
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">First Name</label>
            <input
              type="text"
              name="firstname"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.firstname || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Last Name</label>
            <input
              type="text"
              name="lastname"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.lastname || ''}
              onChange={handleInputChange}
            />
          </div>
          
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Contact Number</label>
            <input
              type="text"
              name="contact_number"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.contact_number || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h2 className="mb-4 text-xl font-semibold">Address</h2>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Address</label>
            <input
              type="text"
              name="address"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.address || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">City</label>
            <input
              type="text"
              name="city"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.city || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">State</label>
            <input
              type="text"
              name="state"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.state || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.country || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-semibold text-gray-700">Zip</label>
            <input
              type="text"
              name="zip"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={user.zip || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <h2 className="mb-4 text-xl font-semibold">Change Password</h2>
          <div className="mb-4">
            <input
              type="password"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter current password"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              className="w-full p-2 border-b-2 border-green-500 focus:outline-none focus:border-green-600"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Confirm new password"
            />
          </div>
          <div className="flex items-end">
            <button onClick={handleChangePassword} className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600">
              <FaLock className="mr-2" /> Change Password
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button onClick={handleProfileUpdate} className="flex items-center px-4 py-2 text-white bg-green-500 rounded-md shadow-lg hover:bg-green-600">
          <FaSave className="mr-2" /> Update Profile
        </button>
      </div>
    </div>
  );
};

export default MyProfile;
